import {
	DeleteOutlined,
	EditOutlined,
	ExclamationCircleFilled,
	MoreOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Modal, Space, Spin, Table, Tag, notification } from "antd";
import { useEffect, useState } from "react";
import { requestGetAttendanceReportList } from "../../../../services/admin.service";
import dayjs from "dayjs";

const columns = [
	{
		title: "Tanggal",
		dataIndex: "date",
		key: "date",
	},
	{
		title: "Laporan",
		dataIndex: "report",
		key: "report",
	},
	{
		title: "Aksi",
		dataIndex: "actions",
		key: "actions",
		align: "right",
	},
];

const ClassroomDailyReportModal = ({ classroom, isVisible, onClose }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [attendanceReports, setAttendanceReports] = useState([]);
	const [mappedReports, setMappedReports] = useState([]);

	useEffect(() => {
		if (isVisible && classroom) {
			fetchClassroomAttendanceReports();
		} else {
			setAttendanceReports([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isVisible, classroom]);

	useEffect(() => {
		updateMappedEvents();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [attendanceReports]);

	const fetchClassroomAttendanceReports = () => {
		setIsLoading(true);

		requestGetAttendanceReportList({
			filter: `type:CLASSROOM,reference:${classroom._id}`,
			limit: 1000,
			order: "date:desc",
		})
			.then((response) => {
				setAttendanceReports(response.data.data);
			})
			.catch((error) => {
				notification.error({
					message: "Gagal mengambil data",
					description: error.message,
				});
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleEdit = (event) => {};

	const handleDelete = (id) => {};

	const handleClose = () => {
		onClose();
	};

	const updateMappedEvents = () => {
		const mapped = attendanceReports.map((report) => {
			return {
				id: report._id,
				date: dayjs(report.date).format("DD MMMM YYYY"),
				report: report.signedAt ? (
					<Tag color="success">Ditandatangani</Tag>
				) : (
					<Tag color="error">Belum</Tag>
				),
				actions: (
					<Space>
						<Dropdown
							trigger={["click"]}
							menu={{
								onClick: (e) => {
									if (e.key === "edit") {
										handleEdit(report);
									} else if (e.key === "delete") {
										Modal.confirm({
											title: "Sebentar",
											icon: <ExclamationCircleFilled />,
											content:
												"Anda yakin akan menghapus laporan ini? Tindakan ini tidak dapat dibatalkan. Semua data terkait akan terputus.",
											okText: "Ya",
											okType: "danger",
											cancelText: "Tidak",
											onOk() {
												handleDelete(report._id);
											},
											onCancel() {},
										});
									}
								},
								items: [
									{
										key: "edit",
										label: "Edit",
										icon: <EditOutlined />,
									},
									{
										key: "delete",
										label: "Hapus",
										icon: <DeleteOutlined />,
									},
								],
							}}
							placement="bottomRight"
							arrow>
							<Button type="default" shape="circle" size="small" icon={<MoreOutlined />} />
						</Dropdown>
					</Space>
				),
			};
		});
		setMappedReports(mapped);
	};

	return (
		<Modal
			maskClosable={false}
			width={512}
			title={"Laporan Harian Kelas"}
			open={isVisible}
			cancelText="Batal"
			onCancel={handleClose}
			footer={[
				<Button key={1} onClick={handleClose}>
					Tutup
				</Button>,
			]}>
			<Spin spinning={isLoading}>
				<Table
					size="small"
					dataSource={mappedReports}
					columns={columns}
					rowKey="id"
					pagination={false}
				/>
			</Spin>
		</Modal>
	);
};

export default ClassroomDailyReportModal;
