/* eslint-disable react-hooks/exhaustive-deps */
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Card, Col, App, Row, Space, Table, Tag, Typography } from "antd";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { requestGetYearList } from "../../../services/teacher.service";
import dayjs from "dayjs";

const { Title } = Typography;

const columns = [
	{
		title: "Nama",
		dataIndex: "name",
		key: "name",
	},
	{
		title: "Tanggal Mulai",
		dataIndex: "startedAt",
		key: "startedAt",
	},
	{
		title: "Tanggal Berakhir",
		dataIndex: "endedAt",
		key: "endedAt",
	},
	{
		title: "Status",
		dataIndex: "status",
		key: "status",
	},
	{
		title: "Aksi",
		dataIndex: "actions",
		key: "actions",
		align: "right",
	},
];

const TeacherYearPage = () => {
	const { notification } = App.useApp();

	useLayoutEffect(() => {
		document.title = "Ruang Pengajar | Tahun Akademik";
	}, []);

	const [years, setYears] = useState([]);
	const [mappedYears, setMappedYears] = useState([]);
	const [isLoading, setLoading] = useState(false);

	useEffect(() => {
		fetchYearList();
	}, []);

	const fetchYearList = () => {
		setLoading(true);
		requestGetYearList()
			.then((response) => {
				setYears(response.data.data);
				updateMappedYear(response.data.data);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const updateMappedYear = (data) => {
		setMappedYears(
			data.map((d) => ({
				key: d._id,
				name: d.name,
				startedAt: dayjs(d.startedAt).format("DD MMM YYYY"),
				endedAt: dayjs(d.endedAt).format("DD MMM YYYY"),
				status: (
					<>
						{d.status === "ACTIVE" ? (
							<Tag style={{ marginRight: 0 }} icon={<CheckCircleOutlined />} color="success">
								Aktif
							</Tag>
						) : (
							<Tag style={{ marginRight: 0 }} icon={<CloseCircleOutlined />} color="error">
								Non-aktif
							</Tag>
						)}
					</>
				),
			}))
		);
	};

	const expandedYearRender = (row) => {
		const columns = [
			{
				title: "Nama",
				dataIndex: "name",
				key: "name",
			},
			{
				title: "Tanggal Mulai",
				dataIndex: "startedAt",
				key: "startedAt",
			},
			{
				title: "Tanggal Akhir",
				dataIndex: "endedAt",
				key: "endedAt",
			},
		];

		const data = [];
		years
			.find((year) => year._id === row.key)
			.semesters.forEach((semester) => {
				data.push({
					key: semester._id,
					name: semester.name,
					startedAt: dayjs(semester.startedAt).format("DD MMM YYYY"),
					endedAt: dayjs(semester.endedAt).format("DD MMM YYYY"),
				});
			});

		return (
			<Table
				title={() => (
					<Row justify="space-between">
						<Title level={5} style={{ marginBottom: 0 }}>
							Daftar Semester
						</Title>
					</Row>
				)}
				columns={columns}
				dataSource={data}
				pagination={false}
			/>
		);
	};

	return (
		<>
			<div className="tabled">
				<Row gutter={[24, 0]}>
					<Col xs={24} xl={24}>
						<Card
							bordered={false}
							title="Daftar Tahun Akademik"
							extra={<Space>{/* <SearchInput onSearch={handleSearch} /> */}</Space>}>
							<div>
								<Table
									columns={columns}
									loading={isLoading}
									dataSource={mappedYears}
									expandedRowRender={expandedYearRender}
									pagination={{
										position: "bottomRight",
										pageSizeOptions: ["10", "20", "50", "100"],
										showSizeChanger: true,
										locale: { items_per_page: "" },
									}}
								/>
							</div>
						</Card>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default TeacherYearPage;
