/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { MoreOutlined } from "@ant-design/icons";
import { Button, Card, Col, Dropdown, App, Row, Space, Table, Tag, Segmented, theme } from "antd";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import {
	requestGetClassroomList,
	requestGetLessonList,
	requestGetYearList,
} from "../../../services/teacher.service";
import AppContext from "../../../store/app.context.tsx";
import { HiOutlineEye, HiOutlinePencil, HiOutlineTrash } from "react-icons/hi2";
import LessonDetailModal from "../lessons/components/LessonDetailModal";
import SearchInput from "../../../components/SearchInput";
import ClassroomDetailModal from "./components/ClassroomDetailModal";

const { useToken } = theme;

const columns = [
	{
		title: "Matpel",
		dataIndex: "subject",
		key: "subject",
	},
	{
		title: "Kelas",
		dataIndex: "classroom",
		key: "classroom",
	},
	{
		title: "Total Jam",
		dataIndex: "totalHour",
		key: "totalHour",
	},
	{
		title: "Jumlah Siswa",
		dataIndex: "studentCount",
		key: "studentCount",
	},
	{
		title: "Jumlah Pengajar",
		dataIndex: "teacherCount",
		key: "teacherCount",
	},
	{
		title: "Aksi",
		dataIndex: "actions",
		key: "actions",
		align: "right",
	},
];

const ClassroomPage = () => {
	const { message, notification, modal } = App.useApp();

	useLayoutEffect(() => {
		document.title = "Ruang Pengajar | Kelas";
	}, []);

	const context = useContext(AppContext);
	const { token } = useToken();

	// SEARCH, FILTER, PAGINATION
	const [keyword, setKeyword] = useState("");
	const [filter, setFilter] = useState({});
	const [sorter, setSorter] = useState({
		_id: "desc",
	});
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);

	const handleSearch = (value) => {
		setKeyword(value.trim());
	};

	const handlePaginate = (pagination, filters, sorter) => {
		setPage(pagination.current);
		setLimit(pagination.pageSize);

		// UPDATE FILTER
		if (filters) {
			const additionalFilter = {};
			Object.keys(filters).forEach((key) => {
				if (filters[key]) {
					additionalFilter[key] = filters[key].join("|");
				} else {
					additionalFilter[key] = "";
				}
			});

			setFilter({
				...filter,
				...additionalFilter,
			});
		} else {
			const tempFilter = {};
			// only allow startedAt and endedAt
			Object.keys(filter).forEach((key) => {
				if (key === "startedAt" || key === "endedAt") {
					tempFilter[key] = filter[key];
				}
			});

			setFilter(tempFilter);
		}

		// UPDATE SORTER
		if (sorter && sorter.field) {
			setSorter({
				[sorter.field]: sorter.order === "ascend" ? "asc" : "desc",
			});
		} else {
			setSorter({
				_id: "desc",
			});
		}
	};
	// SEARCH, FILTER, PAGINATION

	const [lessons, setLessons] = useState([]);
	const [mappedLessons, setMappedLessons] = useState([]);
	const [isLoading, setLoading] = useState(false);

	const [lesson, setLesson] = useState(null);
	const [isLessonFormVisible, setLessonFormVisible] = useState(false);
	const [isLessonDetailVisible, setLessonDetailVisible] = useState(false);
	const [isLessonCloneFormVisible, setLessonCloneFormVisible] = useState(false);

	const [years, setYears] = useState([]);
	const [guidedClassrooms, setGuidedClassrooms] = useState([]);

	const [filteredBy, setFilteredBy] = useState("");
	const [selectedYearId, setSelectedYearId] = useState("");
	const [selectedClassroom, setSelectedClassroom] = useState("");
	const [selectedClassroomId, setSelectedClassroomId] = useState("");

	const [classroom, setClassroom] = useState(null);
	const [isClassroomDetailVisible, setClassroomDetailVisible] = useState(false);

	useEffect(() => {
		setSelectedYearId(context.year._id);
		fetchYearList();
		fetchGuidedClassroomList();
	}, []);

	useEffect(() => {
		if (filter.year) {
			fetchLessonList();
		}
	}, [keyword, filter, page, limit]);

	useEffect(() => {
		if (selectedYearId !== "") {
			const year = years.find((year) => year._id === selectedYearId);
			if (year) {
				setFilteredBy(year.name);
			} else {
				setFilteredBy(context.year.name);
			}
		} else {
			setFilteredBy("");
		}
		setFilter({
			...filter,
			year: selectedYearId,
			classroom: selectedClassroomId,
		});
	}, [selectedYearId, selectedClassroomId]);

	const fetchLessonList = () => {
		setLoading(true);
		let tempFilter = "";
		if (filter) {
			tempFilter = Object.keys(filter)
				.map((key) => `${key}:${filter[key]}`)
				.filter(
					(f) => f.split(":")[1] !== "" && f.split(":")[1] !== undefined && f.split(":")[1] !== null
				)
				.join(",");
		}

		let tempSorter = "";
		if (sorter) {
			tempSorter = Object.keys(sorter)
				.map((key) => `${key}:${sorter[key]}`)
				.filter(
					(s) => s.split(":")[1] !== "" && s.split(":")[1] !== undefined && s.split(":")[1] !== null
				)
				.join(",");
		}

		requestGetLessonList({
			page: page,
			limit: limit,
			order: tempSorter ?? "_id:desc",
			keyword: keyword ?? "",
			filter: tempFilter ?? "",
		})
			.then((response) => {
				setLessons(response.data.data);
				updateMappedLesson(response.data.data);
				setTotal(response.data.pagination.total);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const fetchYearList = () => {
		requestGetYearList({
			page: 1,
			limit: 100,
		})
			.then((response) => {
				setYears(response.data.data);
			})
			.catch((error) => {});
	};

	const fetchGuidedClassroomList = () => {
		requestGetClassroomList({
			page: 1,
			limit: 1000,
			filter: `year:current`,
		})
			.then((response) => {
				setGuidedClassrooms(response.data.data);
			})
			.catch((error) => {});
	};

	const otherButtons = [
		{
			key: "show",
			label: "Lihat",
			icon: <HiOutlineEye />,
		},
		{
			type: "divider",
		},
		{
			key: "delete",
			label: "Hapus",
			icon: <HiOutlineTrash />,
			danger: true,
		},
	];

	const updateMappedLesson = (data) => {
		setMappedLessons(
			data.map((d) => ({
				key: d._id,
				subject: `${d.subject.name}`,
				classroom: `${d.classroom.name}`,
				totalHour: d.totalHour ?? 0,
				studentCount: d.classroom.students.length,
				teacherCount: d.teachers.length,
				actions: (
					<Space>
						<Dropdown
							trigger={["click"]}
							menu={{
								onClick: (e) => {
									if (e.key === "edit") {
										handleEditButton(d);
									} else if (e.key === "show") {
										handleShowButton(d);
									}
								},
								items: otherButtons,
							}}
							placement="bottomRight"
							arrow>
							<Button type="default" shape="circle" size="small" icon={<MoreOutlined />} />
						</Dropdown>
					</Space>
				),
			}))
		);
	};

	const handleEditButton = (lesson) => {
		setLesson(lesson);
		setLessonFormVisible(true);
	};

	const handleShowButton = (lesson) => {
		setLesson(lesson);
		setLessonDetailVisible(true);
	};

	const handleOnCloseLessonDetail = (requireRefresh) => {
		setLesson(null);
		setLessonDetailVisible(false);
		if (requireRefresh) {
			fetchLessonList();
		}
	};

	const handleOnSuccessLessonDetail = () => {
		setLesson(null);
		setLessonDetailVisible(false);
		fetchLessonList();
	};

	const handleOnCloseClassroomDetail = () => {
		setClassroom(null);
		setClassroomDetailVisible(false);
	};

	return (
		<>
			<ClassroomDetailModal
				year={context.year}
				isLoading={isLoading}
				isVisible={isClassroomDetailVisible}
				onLoading={(v) => setLoading(v)}
				id={classroom ? classroom._id : null}
				onClose={handleOnCloseClassroomDetail}
			/>
			<LessonDetailModal
				id={lesson ? lesson._id : null}
				isLoading={isLoading}
				isVisible={isLessonDetailVisible}
				onLoading={(v) => setLoading(v)}
				onClose={handleOnCloseLessonDetail}
				onSuccess={handleOnSuccessLessonDetail}
			/>
			{guidedClassrooms.length > 0 && (
				<Card title="Sebagai Wali Kelas">
					<Row gutter={[24, 24]}>
						{guidedClassrooms.map((classroom) => (
							<Col xs={24} md={12} lg={8} xl={6} key={classroom._id}>
								<Card
									size="small"
									type="inner"
									bordered
									title={<>{classroom.name}</>}
									extra={
										<Tag
											color="green"
											style={{
												marginRight: 0,
											}}>
											Wali Kelas
										</Tag>
									}>
									<Space direction="vertical" align="end" size="small">
										<p>
											Anda adalah wali kelas dari kelas {classroom.name} di tahun ajaran{" "}
											{context.year.name}.
										</p>

										<Button
											type="primary"
											onClick={() => {
												setClassroom(classroom);
												setClassroomDetailVisible(true);
											}}>
											Lihat
										</Button>
									</Space>
								</Card>
							</Col>
						))}
					</Row>
				</Card>
			)}
			<Card
				bordered={false}
				style={{ marginTop: 16 }}
				title="Sebagai Pengajar Pengajar"
				extra={
					<Space>
						<SearchInput
							placeholder="Matpel atau kelas"
							onSearch={handleSearch}
							defaultValue={keyword}
							isLoading={isLoading}
						/>
					</Space>
				}>
				<div>
					<Space direction="vertical" style={{ margin: token.margin }}>
						{/* <Segmented
										size="large"
										options={[
											{ label: "Semua", value: "" },
											...classrooms.reduce((acc, classroom) => {
												if (!acc.find((item) => item.value === classroom.grade)) {
													acc.push({
														label: classroom.grade,
														value: classroom.grade,
													});
												}
												return acc;
											}, []),
										]}
										onChange={(e) => setSelectedClassroom(e)}
									/>
									<Segmented
										size="large"
										options={[
											{ label: "Semua", value: "" },
											...classrooms
												.filter(
													(classroom) => classroom.grade === selectedClassroom || !selectedClassroom
												)
												.map((classroom) => {
													return {
														label: classroom.name,
														value: classroom._id,
													};
												}),
										]}
										onChange={(e) => {
											setSelectedClassroomId(e);
										}}
									/> */}
					</Space>
					<Table
						bordered
						columns={columns}
						loading={isLoading}
						dataSource={mappedLessons}
						pagination={{
							current: page,
							total: total,
							position: "bottomRight",
							pageSizeOptions: ["10", "20", "50", "100"],
							showSizeChanger: true,
							locale: { items_per_page: "item/hal" },
						}}
						onChange={handlePaginate}
						className="ant-border-lesson"
					/>
				</div>
			</Card>
		</>
	);
};

export default ClassroomPage;
