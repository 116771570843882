/* eslint-disable react-hooks/exhaustive-deps */
import {
	Button,
	Checkbox,
	Divider,
	Form,
	Input,
	InputNumber,
	Modal,
	notification,
	Popconfirm,
	Radio,
	Row,
	Select,
	Space,
	Spin,
	Tag,
	Typography,
} from "antd";
import React, { useEffect } from "react";
import { requestAddTransaction, requestGetInvoiceList } from "../../../../services/admin.service";
import dayjs from "dayjs";

const TransactionFormModal = ({ isVisible, isLoading, onLoading, onClose, onSuccess }) => {
	const [type, setType] = React.useState("INCOME"); // ["INCOME", "EXPENSE"]
	const [category, setCategory] = React.useState("");
	const [invoice, setInvoice] = React.useState(null);

	// eslint-disable-next-line no-unused-vars
	const [categories, setCategories] = React.useState([
		{
			value: "TUITION",
			label: "Biaya Sekolah",
			type: ["INCOME"],
		},
		{
			value: "OPERATIONAL",
			label: "Biaya Operasional",
			type: ["EXPENSE"],
		},
		{
			value: "SALARY",
			label: "Gaji",
			type: ["EXPENSE"],
		},
		{
			value: "OTHERS",
			label: "Lainnya",
			type: ["INCOME", "EXPENSE"],
		},
	]);
	const [invoices, setInvoices] = React.useState([]);
	const [keyword, setKeyword] = React.useState("");

	const [form] = Form.useForm();

	useEffect(() => {
		setCategory("");
		setInvoice(null);
		form.setFieldsValue({
			category: "",
			referenceId: null,
		});
	}, [type]);

	useEffect(() => {
		if (isVisible && category === "TUITION") {
			fetchInvoiceList();
		}
	}, [isVisible, keyword, category]);

	const fetchInvoiceList = () => {
		onLoading(true);

		requestGetInvoiceList({
			keyword: keyword,
			page: 1,
			limit: 10,
			order: "issuedAt:desc",
			filter: `status:SENT|PARTIAL`,
		})
			.then((response) => {
				// merge unique data
				const uniqueData = [...invoices, ...response.data.data];
				const uniqueInvoices = [...new Map(uniqueData.map((item) => [item["_id"], item])).values()];
				const sortedInvoices = uniqueInvoices.sort((a, b) => {
					if (a.number < b.number) {
						return -1;
					}
					if (a.number > b.number) {
						return 1;
					}
					return 0;
				});
				setInvoices(sortedInvoices);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				onLoading(false);
			});
	};

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				onLoading(true);

				values.isCompleted = values.isCompleted ?? false;

				requestAddTransaction(values)
					.then((response) => {
						notification["success"]({
							message: "Good job!",
							description: response.data.message,
						});
						form.resetFields();
						setInvoices([]);
						setCategory("");
						onSuccess();
					})
					.catch((error) => {
						if (error.response && error.response.data) {
							notification["error"]({
								message: "Kesalahan!",
								description: error.response.data.message,
							});
						} else {
							notification["error"]({
								message: "Kesalahan!",
								description: error.message,
							});
						}
					})
					.finally(() => {
						onLoading(false);
					});
			})
			.catch((error) => {});
	};

	const handleClose = () => {
		form.resetFields();
		setCategory("");
		setInvoices([]);
		onClose();
	};

	return (
		<>
			<Modal
				maskClosable={false}
				width={512}
				title={`Tambah Pembayaran`}
				open={isVisible}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Batal
					</Button>,
					<Popconfirm
						key={2}
						onConfirm={handleSubmit}
						title="Anda yakin akan menambah pembayaran?"
						okText="Ya"
						cancelText="Tidak">
						<Button type="primary">{"Tambah"}</Button>
					</Popconfirm>,
				]}>
				<Spin spinning={isLoading}>
					<Form form={form} layout="vertical" requiredMark="optional">
						<Form.Item
							style={{ marginTop: 0, marginBottom: 0 }}
							name="nominal"
							label="Nominal"
							required
							rules={[{ required: true, message: "Nominal pembayaran wajib diisi" }]}
							tooltip="Masukkan nominal pembayaran">
							<InputNumber addonBefore="Rp" controls={false} min={0} style={{ width: "100%" }} />
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="type"
							label="Tipe Transaksi"
							required
							initialValue={type}
							rules={[{ required: true, message: "Pilih tipe transaksi" }]}
							tooltip="Pilih tipe transaksi">
							<Radio.Group
								options={[
									{ label: "Pemasukan", value: "INCOME" },
									{ label: "Pengeluaran", value: "EXPENSE" },
								]}
								value={"INCOME"}
								optionType="button"
								buttonStyle="solid"
								onChange={(e) => {
									setType(e.target.value);
								}}
							/>
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="category"
							label="Kategori"
							required
							rules={[{ required: true, message: "Pilih kategori" }]}
							tooltip="Pilih kategori">
							<Select
								onChange={(value) => {
									setCategory(value);
								}}
								placeholder="Pilih kategori"
								style={{ width: "100%" }}>
								{
									// filter categories by type
									categories
										.filter((category) => category.type.includes(type))
										.map((category) => {
											return (
												<Select.Option key={category.value} value={category.value}>
													{category.label}
												</Select.Option>
											);
										})
								}
							</Select>
						</Form.Item>
						{/* if it's tuition, add reference ID */}
						{category === "TUITION" && (
							<>
								<Form.Item
									style={{ marginTop: 12, marginBottom: 0 }}
									name="referenceId"
									label="Nomor Tagihan"
									required
									rules={[{ required: true, message: "Pilih nomor tagihan" }]}
									tooltip="Pilih nomor tagihan">
									<Select
										placeholder="Pilih nomor tagihan"
										style={{ width: "100%" }}
										showSearch
										filterOption={(input, option) => {
											setKeyword(input);
											return (
												option.children.props.children[0]
													.toLowerCase()
													.indexOf(input.toLowerCase()) >= 0
											);
										}}
										onChange={(value) => {
											setInvoice(invoices.find((invoice) => invoice._id === value));
										}}>
										{invoices.map((invoice) => {
											return (
												<Select.Option key={invoice._id} value={invoice._id}>
													<>
														{invoice.number}
														<Tag
															color={
																invoice.status === "SENT"
																	? "processing"
																	: invoice.status === "PARTIAL"
																	? "warning"
																	: "error"
															}
															style={{ marginLeft: 4 }}>
															{invoice.status === "SENT" ? "TERKIRIM" : "SEBAGIAN"}
														</Tag>
													</>
												</Select.Option>
											);
										})}
									</Select>
								</Form.Item>
								{invoice && (
									<Space
										direction="vertical"
										style={{
											width: "100%",
											marginTop: "16px",
											padding: "16px",
											backgroundColor: "#F7F7F7",
											borderRadius: "8px",
											border: "1px dashed #E0E0E0",
										}}>
										<Row key="date" justify="space-between">
											<span>Tanggal</span>
											<span style={{ fontWeight: "bold" }}>
												{dayjs(invoice.date).format("DD MMM YYYY")}
											</span>
										</Row>
										<Row key="name" justify="space-between">
											<span>Nama</span>
											<span style={{ fontWeight: "bold" }}>{invoice.student.name}</span>
										</Row>
										<Row key="classroom" justify="space-between">
											<span>Kelas</span>
											<span
												style={{
													fontWeight: "bold",
												}}>
												{invoice.student.classroom.name}
											</span>
										</Row>
										<Divider
											style={{
												margin: 0,
											}}
										/>
										<Row key="subtotal" justify="space-between">
											<span>Subtotal</span>
											<span
												style={{
													fontWeight: "bold",
												}}>
												{Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(
													invoice.items.map((item) => item.nominal).reduce((a, b) => a + b, 0)
												)}
											</span>
										</Row>
										<Row key="fees" justify="space-between">
											<span>Biaya Tambahan</span>
											<span
												style={{
													fontWeight: "bold",
												}}>
												{Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(
													invoice.fees.map((fee) => fee.nominal).reduce((a, b) => a + b, 0)
												)}
											</span>
										</Row>
										<Row key="total" justify="space-between">
											<span>Total</span>
											<span
												style={{
													fontWeight: "bold",
												}}>
												{Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(
													invoice.items.map((item) => item.nominal).reduce((a, b) => a + b, 0) +
														invoice.fees.map((fee) => fee.nominal).reduce((a, b) => a + b, 0)
												)}
											</span>
										</Row>
									</Space>
								)}
								<Typography.Text
									type="secondary"
									style={{
										marginBottom: 0,
										display: "block",
									}}>
									Jika tagihan belum terdaftar, silahkan tambahkan tagihan terlebih dahulu
								</Typography.Text>
							</>
						)}
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="channel"
							label="Metode Pembayaran"
							required
							rules={[{ required: true, message: "Pilih metode pembayaran" }]}
							tooltip="Pilih metode pembayaran">
							<Select placeholder="Pilih metode pembayaran" style={{ width: "100%" }}>
								<Select.Option value="CASH">Cash</Select.Option>
							</Select>
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="note"
							label="Catatan"
							tooltip="Catatan pembayaran">
							<Input.TextArea placeholder="Catatan pembayaran" />
						</Form.Item>
						<Form.Item style={{ marginTop: 12, marginBottom: 0 }} name="isCompleted">
							<Checkbox
								style={{ width: "100%" }}
								onChange={(e) => {
									form.setFieldsValue({ isCompleted: e.target.checked });
								}}>
								Tandai selesai? Pembayaran akan ditandai telah berhasil
							</Checkbox>
						</Form.Item>
					</Form>
				</Spin>
			</Modal>
		</>
	);
};

export default TransactionFormModal;
