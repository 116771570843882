/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { ExclamationCircleFilled, MoreOutlined, PlusCircleFilled } from "@ant-design/icons";
import {
	Button,
	Card,
	Col,
	Dropdown,
	App,
	Row,
	Space,
	Table,
	Tag,
	Segmented,
	theme,
	Typography,
} from "antd";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import {
	requestGetClassroomList,
	requestGetCurriculumList,
	requestGetReportCardList,
	requestGetYearList,
} from "../../../services/admin.service";
import AppContext from "../../../store/app.context.tsx";
import { YearFilter } from "../../../components/YearFilter";
import { HiOutlineEye, HiOutlineTrash } from "react-icons/hi2";
import SearchInput from "../../../components/SearchInput";
import { useSearchParams } from "react-router-dom";
import { CurriculumFilter } from "../../../components/CurriculumFilter";
import ReportCardFormModal from "./components/ReportCardFormModal";
import ReportCardDetailModal from "./components/ReportCardDetailModal";

const { useToken } = theme;

const columns = [
	{
		title: "Kurikulum",
		dataIndex: "curriculum",
		key: "curriculum",
	},
	{
		title: "Tahun Akademik",
		dataIndex: "year",
		key: "year",
	},
	{
		title: "Kelas",
		dataIndex: "classroom",
		key: "classroom",
	},
	{
		title: "Nama",
		dataIndex: "student",
		key: "student",
	},
	{
		title: "Aksi",
		dataIndex: "actions",
		key: "actions",
		align: "right",
	},
];

const ReportCardPage = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { notification, modal } = App.useApp();

	useLayoutEffect(() => {
		document.title = "Ruang Admin | Rapor | Buku Rapor";
	}, []);

	const context = useContext(AppContext);
	const { token } = useToken();

	// SEARCH, FILTER, PAGINATION
	const [keyword, setKeyword] = useState("");
	const [filter, setFilter] = useState({});
	const [sorter, setSorter] = useState({
		_id: "desc",
	});
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);

	const handleSearch = (value) => {
		setKeyword(value.trim());
	};

	const handlePaginate = (pagination, filters, sorter) => {
		setPage(pagination.current);
		setLimit(pagination.pageSize);

		// UPDATE FILTER
		if (filters) {
			const additionalFilter = {};
			Object.keys(filters).forEach((key) => {
				if (filters[key]) {
					additionalFilter[key] = filters[key].join("|");
				} else {
					additionalFilter[key] = "";
				}
			});

			setFilter({
				...filter,
				...additionalFilter,
			});
		} else {
			const tempFilter = {};
			// only allow startedAt and endedAt
			Object.keys(filter).forEach((key) => {
				if (key === "year" || key === "curriculum" || key === "classroom") {
					tempFilter[key] = filter[key];
				}
			});

			setFilter(tempFilter);
		}

		// UPDATE SORTER
		if (sorter && sorter.field) {
			setSorter({
				[sorter.field]: sorter.order === "ascend" ? "asc" : "desc",
			});
		} else {
			setSorter({
				_id: "desc",
			});
		}
	};
	// SEARCH, FILTER, PAGINATION

	const [curriculums, setCurriculums] = useState([]);
	const [reportCards, setReportCards] = useState([]);
	const [mappedReportCards, setMappedReportCards] = useState([]);
	const [isLoading, setLoading] = useState(false);

	const [years, setYears] = useState([]);
	const [classrooms, setClassrooms] = useState([]);

	const [filteredBy, setFilteredBy] = useState("");
	const [selectedYearId, setSelectedYearId] = useState("");
	const [selectedClassroom, setSelectedClassroom] = useState("");
	const [selectedClassroomId, setSelectedClassroomId] = useState("");
	const [selectedCurriculum, setSelectedCurriculum] = useState(null);

	const [selectedReportCardId, setSelectedReportCardId] = useState("");
	const [isReportCardDetailVisible, setReportCardDetailVisible] = useState(false);
	const [isReportCardFormVisible, setReportCardFormVisible] = useState(false);

	useEffect(() => {
		setSelectedYearId(context.year._id);
		fetchCurriculumList();
		fetchYearList();
		fetchClassroomList();
	}, []);

	useEffect(() => {
		setSearchParams({ keyword: keyword, page: page, limit: limit });

		if (filter.year) {
			fetchReportCardList();
			fetchClassroomList();
		}
	}, [keyword, filter, page, limit]);

	useEffect(() => {
		if (searchParams.has("keyword")) {
			setKeyword(searchParams.get("keyword"));
		}
		if (searchParams.has("page")) {
			setPage(parseInt(searchParams.get("page")));
		}
		if (searchParams.has("limit")) {
			setLimit(parseInt(searchParams.get("limit")));
		}
	}, []);

	useEffect(() => {
		if (selectedYearId !== "") {
			const year = years.find((year) => year._id === selectedYearId);
			if (year) {
				setFilteredBy(year.name);
			} else {
				setFilteredBy(context.year.name);
			}
		} else {
			setFilteredBy("");
		}
		setFilter({
			...filter,
			curriculum: selectedCurriculum ? selectedCurriculum._id : "",
			year: selectedYearId,
			classroom: selectedClassroomId,
		});
	}, [selectedCurriculum, selectedYearId, selectedClassroomId]);

	const fetchCurriculumList = () => {
		requestGetCurriculumList({
			page: 1,
			limit: 100,
		})
			.then((response) => {
				setCurriculums(response.data.data);
			})
			.catch((error) => {
				notification["error"]({
					message: "Kesalahan!",
					description: error.message,
				});
			});
	};

	const fetchReportCardList = () => {
		setLoading(true);
		let tempFilter = "";
		if (filter) {
			tempFilter = Object.keys(filter)
				.map((key) => `${key}:${filter[key]}`)
				.filter(
					(f) => f.split(":")[1] !== "" && f.split(":")[1] !== undefined && f.split(":")[1] !== null
				)
				.join(",");
		}

		let tempSorter = "";
		if (sorter) {
			tempSorter = Object.keys(sorter)
				.map((key) => `${key}:${sorter[key]}`)
				.filter(
					(s) => s.split(":")[1] !== "" && s.split(":")[1] !== undefined && s.split(":")[1] !== null
				)
				.join(",");
		}

		requestGetReportCardList({
			page: page,
			limit: limit,
			order: tempSorter ?? "_id:desc",
			keyword: keyword ?? "",
			filter: tempFilter ?? "",
		})
			.then((response) => {
				setReportCards(response.data.data);
				updateMappedReportCards(response.data.data);
				setTotal(response.data.pagination.total);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const fetchYearList = () => {
		requestGetYearList({
			page: 1,
			limit: 100,
		})
			.then((response) => {
				setYears(response.data.data);
			})
			.catch((error) => {});
	};

	const fetchClassroomList = () => {
		requestGetClassroomList({
			page: 1,
			limit: 1000,
			filter: selectedYearId ? `year:${selectedYearId}` : "",
		})
			.then((response) => {
				setClassrooms(response.data.data);
			})
			.catch((error) => {});
	};

	const otherButtons = [
		{
			key: "show",
			label: "Lihat",
			icon: <HiOutlineEye />,
		},
		{
			type: "divider",
		},
		{
			key: "delete",
			label: "Hapus",
			icon: <HiOutlineTrash />,
			danger: true,
		},
	];

	const updateMappedReportCards = (data) => {
		setMappedReportCards(
			data.map((d) => ({
				key: d._id,
				curriculum: d.curriculum.name,
				year: (
					<Space direction="vertical" size="small">
						<Space direction="horizontal" size="small">
							<Typography.Text strong>{d.year.name}</Typography.Text>
							{context.year && d.year._id === context.year._id && (
								<Typography.Text strong>{<Tag color="green">Aktif</Tag>}</Typography.Text>
							)}
						</Space>
						<Typography.Text type="secondary">Semester {d.semester.name}</Typography.Text>
					</Space>
				),
				classroom: `${d.classroom.name}`,
				student: (
					<Space direction="vertical" size="small">
						<Space direction="horizontal" size="small">
							<Typography.Text strong>{d.student.name}</Typography.Text>
						</Space>
						<Typography.Text type="secondary">{d.student.number}</Typography.Text>
					</Space>
				),
				actions: (
					<Space>
						<Dropdown
							trigger={["click"]}
							menu={{
								onClick: (e) => {
									if (e.key === "show") {
										handleOnShowButton(d._id);
									} else if (e.key === "delete") {
										modal.confirm({
											title: "Sebentar",
											icon: <ExclamationCircleFilled />,
											content:
												"Anda yakin akan menghapus pelajaran ini? Tindakan ini tidak dapat dibatalkan. Pelajaran yang sudah dihapus tidak dapat dikembalikan.",
											okText: "Ya",
											okType: "danger",
											cancelText: "Tidak",
											onOk() {
												// handleDeleteButton(d._id);
											},
											onCancel() {},
										});
									}
								},
								items: otherButtons,
							}}
							placement="bottomRight"
							arrow>
							<Button type="default" shape="circle" size="small" icon={<MoreOutlined />} />
						</Dropdown>
					</Space>
				),
			}))
		);
	};

	const handleGenerateButton = () => {
		setReportCardFormVisible(true);
	};

	const handleOnCloseReportCardForm = () => {
		setReportCardFormVisible(false);
	};

	const handleOnSuccessReportCardForm = () => {
		setReportCardFormVisible(false);
		fetchReportCardList();
	};

	const handleOnShowButton = (id) => {
		setSelectedReportCardId(id);
		setReportCardDetailVisible(true);
	};

	const handleOnCloseReportCardDetail = () => {
		setReportCardDetailVisible(false);
		setSelectedReportCardId("");
	};

	return (
		<>
			<ReportCardFormModal
				isVisible={isReportCardFormVisible}
				onClose={handleOnCloseReportCardForm}
				onSuccess={handleOnSuccessReportCardForm}
				curriculums={curriculums}
			/>
			<ReportCardDetailModal
				isVisible={isReportCardDetailVisible}
				reportCardId={selectedReportCardId}
				onCancel={() => setReportCardDetailVisible(false)}
			/>
			<Card
				bordered={false}
				title="Daftar Buku Rapor"
				extra={
					<Space>
						<SearchInput
							placeholder="Matpel atau kelas"
							onSearch={handleSearch}
							defaultValue={keyword}
							isLoading={isLoading}
						/>
						<YearFilter
							activeYear={context.year}
							years={years}
							filteredBy={{
								key: selectedYearId,
								value: filteredBy,
							}}
							filterBy={(key) => {
								setSelectedYearId(key);
							}}
						/>
						<CurriculumFilter
							curriculums={curriculums}
							filteredBy={{
								key: selectedCurriculum ? selectedCurriculum._id : "",
								value: selectedCurriculum ? selectedCurriculum.name : "Semua Kurikulum",
							}}
							filterBy={(key) => {
								if (key === "") {
									setSelectedCurriculum(null);
								} else {
									setSelectedCurriculum(curriculums.find((curriculum) => curriculum._id === key));
								}
							}}
							resettable={true}
						/>
						<Button icon={<PlusCircleFilled />} type="default" onClick={handleGenerateButton}>
							Buat Buku Rapor
						</Button>
					</Space>
				}>
				<div>
					<Space direction="vertical">
						<Segmented
							size="large"
							options={[
								{ label: "Semua", value: "" },
								...classrooms.reduce((acc, classroom) => {
									if (!acc.find((item) => item.value === classroom.grade)) {
										acc.push({
											label: classroom.grade,
											value: classroom.grade,
										});
									}
									return acc;
								}, []),
							]}
							onChange={(e) => setSelectedClassroom(e)}
						/>
						<Segmented
							size="large"
							options={[
								{ label: "Semua", value: "" },
								...classrooms
									.filter(
										(classroom) => classroom.grade === selectedClassroom || !selectedClassroom
									)
									.map((classroom) => {
										return {
											label: classroom.name,
											value: classroom._id,
										};
									}),
							]}
							onChange={(e) => {
								setSelectedClassroomId(e);
							}}
						/>
					</Space>
					<Table
						style={{ marginTop: 16 }}
						bordered
						columns={columns}
						loading={isLoading}
						dataSource={mappedReportCards}
						pagination={{
							current: page,
							total: total,
							position: "bottomRight",
							pageSizeOptions: ["10", "20", "50", "100"],
							showSizeChanger: true,
							locale: { items_per_page: "item/hal" },
						}}
						onChange={handlePaginate}
						className="ant-border-lesson"
					/>
				</div>
			</Card>
		</>
	);
};

export default ReportCardPage;
