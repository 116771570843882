import React, { useState } from "react";
import { Layout } from "antd";
import CustomHeader from "./CustomHeader";
import CustomFooter from "./CustomFooter";
import CustomSider from "./CustomSider";
import TawkToChat from "../TakwToChat";

const { Content } = Layout;

function Main({ children }: { children?: React.ReactNode }) {
  const [collapsed, setCollapsed] = useState(false);
  const [query, setQuery] = useState("");

  return (
    <Layout>
      <CustomSider
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        query={query}
      />
      <Layout
        style={{
          marginInlineStart: collapsed ? 80 : 248,
        }}
      >
        <CustomHeader
          onSearch={(value: any) => setQuery(value)}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
        <Content
          style={{
            margin: "16px 0",
            padding: "0 16px",
            overflow: "initial",
            minHeight: "100vh",
          }}
        >
          <div>{children}</div>
        </Content>
        <CustomFooter />
      </Layout>
      {/* <CrispChat /> */}
      <TawkToChat />
    </Layout>
  );
}

export default Main;
