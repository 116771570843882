import {
	Button,
	DatePicker,
	Form,
	Input,
	Modal,
	Popconfirm,
	Select,
	Spin,
	Tabs,
	message,
	notification,
} from "antd";
import React, { useEffect } from "react";
import { addStudent, requestEditStudent } from "../../../../services/admin.service";
import dayjs from "dayjs";

const StudentFormModal = ({ student, isVisible, isLoading, onLoading, onClose, onSuccess }) => {
	const [tabIndex, setTabIndex] = React.useState("1");
	const [items, setItems] = React.useState([]);

	const [form] = Form.useForm();
	const fatherPhone = Form.useWatch(["parent", "father", "phone"], form);
	const motherPhone = Form.useWatch(["parent", "mother", "phone"], form);
	const guardianPhone = Form.useWatch(["parent", "guardian", "phone"], form);

	useEffect(() => {
		if (student) {
			form.setFieldsValue({
				number: student.number,
				name: student.name,
				birthPlace: student.birthPlace,
				birthDate: dayjs(student.birthDate, "YYYY-MM-DD"),
				address: student.address,
				gender: student.gender,
				...(student.parent
					? {
							parent: student.parent,
					  }
					: {}),
			});

			setItems(items);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [student]);

	useEffect(() => {
		// if it starts with 0, change to 62
		if (fatherPhone && fatherPhone.startsWith("0")) {
			form.setFieldsValue({
				parent: {
					father: {
						phone: `62${fatherPhone.slice(1)}`,
					},
				},
			});
		}

		if (motherPhone && motherPhone.startsWith("0")) {
			console.log("motherPhone", motherPhone);
			form.setFieldsValue({
				parent: {
					mother: {
						phone: `62${motherPhone.slice(1)}`,
					},
				},
			});
		}

		if (guardianPhone && guardianPhone.startsWith("0")) {
			form.setFieldsValue({
				parent: {
					guardian: {
						phone: `62${guardianPhone.slice(1)}`,
					},
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fatherPhone, motherPhone, guardianPhone]);

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				onLoading(true);
				if (student) {
					requestEditStudent(student._id, values)
						.then((response) => {
							message.success("Data siswa berhasil diubah");
							form.resetFields();
							setTabIndex("1");
							onSuccess();
						})
						.catch((error) => {
							if (error.response && error.response.data) {
								notification.error({
									message: "Kesalahan!",
									description: error.response.data.message,
								});
							} else {
								notification.error({
									message: "Kesalahan!",
									description: error.message,
								});
							}
						})
						.finally(() => {
							onLoading(false);
						});
				} else {
					addStudent(values)
						.then((response) => {
							notification["success"]({
								message: "Good job!",
								description: response.data.message,
							});
							form.resetFields();
							setTabIndex("1");
							onSuccess();
						})
						.catch((error) => {
							if (error.response && error.response.data) {
								notification["error"]({
									message: "Kesalahan!",
									description: error.response.data.message,
								});
							} else {
								notification["error"]({
									message: "Kesalahan!",
									description: error.message,
								});
							}
						})
						.finally(() => {
							onLoading(false);
						});
				}
			})
			.catch((error) => {});
	};

	const handleClose = () => {
		form.resetFields();
		setTabIndex("1");
		onClose();
	};

	return (
		<>
			<Modal
				maskClosable={false}
				width={512}
				title={student === null || student === undefined ? "Tambah Siswa" : "Ubah Siswa"}
				open={isVisible}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Batal
					</Button>,
					<Popconfirm
						key={2}
						onConfirm={handleSubmit}
						title="Yakin akan menyimpan data siswa?"
						okText="Ya"
						cancelText="Tidak">
						<Button type="primary">{student ? "Simpan" : "Tambah"}</Button>
					</Popconfirm>,
				]}>
				<Spin spinning={isLoading}>
					<Form form={form} layout="vertical" requiredMark="optional">
						<Tabs
							activeKey={tabIndex}
							onChange={(key) => setTabIndex(key)}
							items={[
								{
									key: "1",
									label: `Data Siswa`,
									children: (
										<>
											<Form.Item
												style={{ marginTop: 0, marginBottom: 0 }}
												name="number"
												label="NISN"
												required
												rules={[
													{
														required: true,
														message: "Masukkan NISN",
													},
												]}
												tooltip="Masukkan NISN">
												<Input style={{ width: "100%" }} />
											</Form.Item>
											<Form.Item
												style={{ marginTop: 12, marginBottom: 0 }}
												name="name"
												label="Nama"
												required
												rules={[
													{
														required: true,
														message: "Masukkan nama siswa",
													},
												]}
												tooltip="Masukkan nama siswa">
												<Input style={{ width: "100%" }} />
											</Form.Item>
											<Form.Item
												style={{ marginTop: 12, marginBottom: 0 }}
												name="birthPlace"
												label="Tempat Lahir"
												tooltip="Masukkan tempat lahir siswa">
												<Input style={{ width: "100%" }} />
											</Form.Item>
											<Form.Item
												style={{ marginTop: 12, marginBottom: 0 }}
												name="birthDate"
												label="Tanggal Lahir"
												tooltip="Masukkan tanggal lahir siswa">
												<DatePicker style={{ width: "100%" }} />
											</Form.Item>
											<Form.Item
												style={{ marginTop: 12, marginBottom: 0 }}
												name="gender"
												label="Jenis Kelamin"
												required
												rules={[
													{
														required: true,
														message: "Pilih jenis kelamin siswa",
													},
												]}
												tooltip="Pilih jenis kelamin siswa">
												<Select style={{ width: "100%" }}>
													<Select.Option value="MALE">Laki-laki</Select.Option>
													<Select.Option value="FEMALE">Perempuan</Select.Option>
												</Select>
											</Form.Item>
											<Form.Item
												style={{ marginTop: 12, marginBottom: 0 }}
												name="address"
												label="Alamat"
												tooltip="Masukkan alamat siswa">
												<Input.TextArea style={{ width: "100%" }} />
											</Form.Item>
										</>
									),
								},
								{
									key: "2",
									label: `Data Ayah`,
									children: (
										<>
											<Form.Item
												style={{ marginTop: 0, marginBottom: 0 }}
												name={["parent", "father", "name"]}
												label="Nama Ayah"
												tooltip="Masukkan nama ayah siswa">
												<Input style={{ width: "100%" }} />
											</Form.Item>
											<Form.Item
												style={{ marginTop: 12, marginBottom: 0 }}
												name={["parent", "father", "email"]}
												label="Email Ayah"
												tooltip="Masukkan email ayah siswa">
												<Input style={{ width: "100%" }} />
											</Form.Item>
											<Form.Item
												style={{ marginTop: 12, marginBottom: 0 }}
												name={["parent", "father", "phone"]}
												label="Telepon Ayah"
												tooltip="Masukkan telepon ayah siswa">
												<Input style={{ width: "100%" }} />
											</Form.Item>
											<Form.Item
												style={{ marginTop: 12, marginBottom: 0 }}
												name={["parent", "father", "address"]}
												label="Alamat Ayah"
												tooltip="Masukkan alamat ayah siswa">
												<Input.TextArea style={{ width: "100%" }} />
											</Form.Item>
											<Form.Item
												style={{ marginTop: 12, marginBottom: 0 }}
												name={["parent", "father", "occupation"]}
												label="Pekerjaan Ayah"
												tooltip="Masukkan pekerjaan ayah siswa">
												<Input style={{ width: "100%" }} />
											</Form.Item>
										</>
									),
								},
								{
									key: "3",
									label: `Data Ibu`,
									children: (
										<>
											<Form.Item
												style={{ marginTop: 0, marginBottom: 0 }}
												name={["parent", "mother", "name"]}
												label="Nama Ibu"
												tooltip="Masukkan nama ibu siswa">
												<Input style={{ width: "100%" }} />
											</Form.Item>
											<Form.Item
												style={{ marginTop: 12, marginBottom: 0 }}
												name={["parent", "mother", "email"]}
												label="Email Ibu"
												tooltip="Masukkan email ibu siswa">
												<Input style={{ width: "100%" }} />
											</Form.Item>
											<Form.Item
												style={{ marginTop: 12, marginBottom: 0 }}
												name={["parent", "mother", "phone"]}
												label="Telepon Ibu"
												tooltip="Masukkan telepon ibu siswa">
												<Input style={{ width: "100%" }} />
											</Form.Item>
											<Form.Item
												style={{ marginTop: 12, marginBottom: 0 }}
												name={["parent", "mother", "address"]}
												label="Alamat Ibu"
												tooltip="Masukkan alamat ibu siswa">
												<Input.TextArea style={{ width: "100%" }} />
											</Form.Item>
											<Form.Item
												style={{ marginTop: 12, marginBottom: 0 }}
												name={["parent", "mother", "occupation"]}
												label="Pekerjaan Ibu"
												tooltip="Masukkan pekerjaan ibu siswa">
												<Input style={{ width: "100%" }} />
											</Form.Item>
										</>
									),
								},
								{
									key: "4",
									label: `Data Wali Siswa`,
									children: (
										<>
											<Form.Item
												style={{ marginTop: 0, marginBottom: 0 }}
												name={["parent", "guardian", "name"]}
												label="Nama Wali"
												tooltip="Masukkan nama wali siswa">
												<Input style={{ width: "100%" }} />
											</Form.Item>
											<Form.Item
												style={{ marginTop: 12, marginBottom: 0 }}
												name={["parent", "guardian", "email"]}
												label="Email Wali"
												tooltip="Masukkan email wali siswa">
												<Input style={{ width: "100%" }} />
											</Form.Item>
											<Form.Item
												style={{ marginTop: 12, marginBottom: 0 }}
												name={["parent", "guardian", "phone"]}
												label="Telepon Wali"
												tooltip="Masukkan telepon wali siswa">
												<Input style={{ width: "100%" }} />
											</Form.Item>
											<Form.Item
												style={{ marginTop: 12, marginBottom: 0 }}
												name={["parent", "guardian", "address"]}
												label="Alamat Wali"
												tooltip="Masukkan alamat wali siswa">
												<Input.TextArea style={{ width: "100%" }} />
											</Form.Item>
											<Form.Item
												style={{ marginTop: 12, marginBottom: 0 }}
												name={["parent", "guardian", "occupation"]}
												label="Pekerjaan Wali"
												tooltip="Masukkan pekerjaan wali siswa">
												<Input style={{ width: "100%" }} />
											</Form.Item>
										</>
									),
								},
							]}
						/>
					</Form>
				</Spin>
			</Modal>
		</>
	);
};

export default StudentFormModal;
