import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";
// const weekFormat = "MM/DD";
// const monthFormat = "YYYY/MM";

dayjs.extend(customParseFormat);

const DateRangeFilter = ({
	startedAt = dayjs().format(dateFormat),
	endedAt = dayjs().format(dateFormat),
	onOk = (start, end) => {},
}) => (
	<RangePicker
		defaultValue={[dayjs(startedAt, dateFormat), dayjs(endedAt, dateFormat)]}
		format={dateFormat}
		onChange={(dates, dateStrings) => {
			onOk(dateStrings[0], dateStrings[1]);
		}}
		showNow={true}
	/>
);

export default DateRangeFilter;
