import { useContext, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import Main from "./components/layout/Main";
import "react-quill/dist/quill.snow.css";
import AppContext from "./store/app.context";
import axios from "axios";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setUserId, setUserProperties } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import RegisterPage from "./pages/auth/RegisterPage";
import LoginPage from "./pages/auth/LoginPage";
import DashboardPage from "./pages/admin/dashboard";
import SettingPage from "./pages/settings/SettingPage";
import AnnouncementPage from "./pages/admin/announcements";
import YearPage from "./pages/admin/years";
import SubjectPage from "./pages/admin/subjects";
import ClassroomPage from "./pages/admin/classrooms";
import LessonPage from "./pages/admin/lessons";
import StudentPage from "./pages/admin/students";
import TeacherPage from "./pages/admin/teachers";
import EventPage from "./pages/admin/events";
import FeePage from "./pages/admin/fees";
import InvoicePage from "./pages/admin/invoices";
import TransactionPage from "./pages/admin/transactions";
import UserPage from "./pages/admin/users";
import ForgotPasswordPage from "./pages/auth/ForgotPasswordPage";
import TeacherDashboardPage from "./pages/teacher/dashboard";
import TeacherYearPage from "./pages/teacher/years";
import TeacherClassroomPage from "./pages/teacher/classrooms";
import TeacherLessonPage from "./pages/teacher/lessons";
import TeacherEventPage from "./pages/teacher/events";
import dayjs from "dayjs";
import ArticlePage from "./pages/admin/articles";
import PocketPage from "./pages/admin/pockets";
import PaymentTransactionPage from "./pages/admin/payment-transactions";
import CurriculumPage from "./pages/admin/curriculums";
import ReportAssessmentPage from "./pages/admin/report-assessments";
import ReportCardPage from "./pages/admin/report-card";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import { requestGetProfile, requestRefreshToken } from "./services/user.service";
import NotificationPage from "./pages/notifications";
import { notification } from "antd";
import ExtracurricularPage from "./pages/admin/extracurriculars";

require("dayjs/locale/id");

dayjs.extend(utc);
dayjs.extend(tz);

dayjs.locale("id");
dayjs.tz.setDefault("Asia/Jakarta");

const firebaseConfig = {
	apiKey: "AIzaSyBsmfJ-_osMGQ62UjlbmEe8B67si06hWtU",
	authDomain: "codeiva-smartiva.firebaseapp.com",
	projectId: "codeiva-smartiva",
	storageBucket: "codeiva-smartiva.appspot.com",
	messagingSenderId: "781106445573",
	appId: "1:781106445573:web:4173828a36b1c1faf0cb6c",
	measurementId: "G-JKLW1FCX4X",
};

const vapidKey =
	"BEQonUIHCPsKHIUtwhYS5Q2M0uZcC6wJEC-R38QDJWBozdn2d-IkLszAaSA-Ex5r--oQBJz9-zxOekYhndqPaOU";

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);

onMessage(messaging, (payload) => {
	const notification = new Notification("Smartiva", {
		body: payload?.data?.body,
		icon: "/favicon.png",
	});

	notification.onclick = () => {
		window.focus();
	};
});

function App() {
	const context = useContext(AppContext);
	const navigate = useNavigate();
	const location = useLocation();

	axios.defaults.withCredentials = true;
	axios.defaults.headers.get["X-Language"] = "id";
	axios.defaults.headers.get["X-Timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
	axios.defaults.headers.post["X-Language"] = "id";
	axios.defaults.headers.post["X-Timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
	axios.defaults.headers.patch["X-Language"] = "id";
	axios.defaults.headers.patch["X-Timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
	axios.defaults.headers.delete["X-Language"] = "id";
	axios.defaults.headers.delete["X-Timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
	axios.interceptors.response.use(
		function (response) {
			return response;
		},
		function (error) {
			console.log(error.response);
			if (error.response.status === 401) {
				context.logout();
				navigate("/login");
			}
			return Promise.reject(error);
		}
	);

	useEffect(() => {
		if (context.isLoggedIn) {
			requestPermission();

			requestGetProfile().then((response) => {
				if (response.data && response.data.data) {
					context.setUser(response.data.data);
					setUserProperties(analytics, {
						id: response.data.data._id,
						name: response.data.data.name,
						email: response.data.data.email,
						phone: response.data.data.phone,
						role: response.data.data.role,
					});
					setUserId(analytics, response.data.data._id);
				}
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [context.isLoggedIn]);

	useEffect(() => {
		logEvent(analytics, "screen_view", {
			firebase_screen: document.title,
			firebase_screen_class: location.pathname,
		});
	}, [location.pathname]);

	function requestPermission() {
		Notification.requestPermission().then((permission) => {
			const oldToken = localStorage.getItem("messaging_token");
			if (permission === "granted") {
				getToken(messaging, {
					vapidKey: vapidKey,
				}).then((currentToken) => {
					if (currentToken && currentToken !== oldToken) {
						localStorage.setItem("messaging_token", currentToken);

						requestRefreshToken(currentToken)
							.then((response) => {
								logEvent(analytics, "messaging_token_updated", {
									token: currentToken,
									user: {
										id: context.user._id,
										name: context.user.name,
									},
								});
							})
							.catch((error) => {
								console.error("Error refreshing token: ", error);
							});
					}
				});
			} else {
				notification.error({
					message: "Izin ditolak!",
					description: "Anda tidak akan menerima notifikasi dari aplikasi ini.",
				});
			}
		});
	}

	return (
		<div className="App">
			<Routes>
				{!context.isLoggedIn && <Route path="/register" element={<RegisterPage />} />}
				{!context.isLoggedIn && <Route path="/login" element={<LoginPage />} />}
				{!context.isLoggedIn && <Route path="/forgot-password" element={<ForgotPasswordPage />} />}

				{/* Admin Start */}
				{context.isLoggedIn && (context.role === "MASTER" || context.role === "ADMIN") && (
					<>
						<Route
							path="/admin/dashboard"
							element={
								<Main>
									<DashboardPage />
								</Main>
							}
						/>
						<Route
							path="/admin/users"
							element={
								<Main>
									<UserPage />
								</Main>
							}
						/>
						<Route
							path="/admin/publication/announcements"
							element={
								<Main>
									<AnnouncementPage />
								</Main>
							}
						/>
						<Route
							path="/admin/academic/curriculums"
							element={
								<Main>
									<CurriculumPage />
								</Main>
							}
						/>
						<Route
							path="/admin/academic/years"
							element={
								<Main>
									<YearPage />
								</Main>
							}
						/>
						<Route
							path="/admin/subjects"
							element={
								<Main>
									<SubjectPage />
								</Main>
							}
						/>
						<Route
							path="/admin/academic/classrooms"
							element={
								<Main>
									<ClassroomPage />
								</Main>
							}
						/>
						<Route
							path="/admin/academic/lessons"
							element={
								<Main>
									<LessonPage />
								</Main>
							}
						/>
						<Route
							path="/admin/students"
							element={
								<Main>
									<StudentPage />
								</Main>
							}
						/>
						<Route
							path="/admin/teachers"
							element={
								<Main>
									<TeacherPage />
								</Main>
							}
						/>
						<Route
							path="/admin/extracurriculars"
							element={
								<Main>
									<ExtracurricularPage />
								</Main>
							}
						/>
						<Route
							path="/admin/events"
							element={
								<Main>
									<EventPage />
								</Main>
							}
						/>
						<Route
							path="/admin/finance/fees"
							element={
								<Main>
									<FeePage />
								</Main>
							}
						/>
						<Route
							path="/admin/finance/invoices"
							element={
								<Main>
									<InvoicePage />
								</Main>
							}
						/>
						<Route
							path="/admin/finance/transactions"
							element={
								<Main>
									<TransactionPage />
								</Main>
							}
						/>
						<Route
							path="/admin/pocket/accounts"
							element={
								<Main>
									<PocketPage />
								</Main>
							}
						/>
						<Route
							path="/admin/payment/transactions"
							element={
								<Main>
									<PaymentTransactionPage />
								</Main>
							}
						/>
						<Route
							path="/admin/report/assessments"
							element={
								<Main>
									<ReportAssessmentPage />
								</Main>
							}
						/>
						<Route
							path="/admin/report/cards"
							element={
								<Main>
									<ReportCardPage />
								</Main>
							}
						/>
						<Route
							path="/admin/publication/articles"
							element={
								<Main>
									<ArticlePage />
								</Main>
							}
						/>
						<Route
							path="/admin/other/settings"
							element={
								<Main>
									<SettingPage />
								</Main>
							}
						/>
						<Route
							path="/*"
							element={
								context.isLoggedIn ? <Navigate to="/admin/dashboard" /> : <Navigate to="/login" />
							}
						/>
					</>
				)}
				{/* Admin End */}

				{/* Teacher Start */}
				{context.isLoggedIn && context.role === "TEACHER" && (
					<>
						<Route
							path="/teacher/dashboard"
							element={
								<Main>
									<TeacherDashboardPage />
								</Main>
							}
						/>
						<Route
							path="/teacher/academic/years"
							element={
								<Main>
									<TeacherYearPage />
								</Main>
							}
						/>
						<Route
							path="/teacher/academic/classrooms"
							element={
								<Main>
									<TeacherClassroomPage />
								</Main>
							}
						/>
						<Route
							path="/teacher/academic/lessons"
							element={
								<Main>
									<TeacherLessonPage />
								</Main>
							}
						/>
						<Route
							path="/teacher/events"
							element={
								<Main>
									<TeacherEventPage />
								</Main>
							}
						/>
						<Route
							path="/*"
							element={
								context.isLoggedIn ? <Navigate to="/teacher/dashboard" /> : <Navigate to="/login" />
							}
						/>
					</>
				)}
				{/* Teacher End */}

				{/* User Start */}
				<Route
					path="/notifications"
					element={
						<Main>
							<NotificationPage />
						</Main>
					}
				/>
				{/* User Start */}

				{/* Default */}
				<Route path="/*" element={<Navigate to="/login" />} />
				{/* Default */}
			</Routes>
		</div>
	);
}

export default App;
