import {
	App,
	Button,
	Card,
	Checkbox,
	Col,
	DatePicker,
	Descriptions,
	Dropdown,
	Form,
	Input,
	Modal,
	Popconfirm,
	Radio,
	Row,
	Select,
	Space,
	Spin,
	Steps,
	Table,
	Tabs,
	TimePicker,
	Typography,
} from "antd";
import {
	CheckCircleFilled,
	CloseCircleFilled,
	CloseOutlined,
	DeleteOutlined,
	MoreOutlined,
	PlusCircleFilled,
	PlusOutlined,
} from "@ant-design/icons";
import React, { useEffect } from "react";
import {
	requestUnassignTeacherFromExtracurricular,
	requestUnenrollStudentsFromExtracurricular,
	requestShowExtracurricular,
	requestScheduleExtracurricular,
} from "../../../../services/admin.service";
import dayjs from "dayjs";
import ExtracurricularTeacherAssignFormModal from "./ExtracurricularTeacherAssignFormModal";
import ExtracurricularStudentEnrollFormModal from "./ExtracurricularStudentEnrollFormModal";
import ExtracurricularReportFormModal from "./ExtracurricularReportFormModal";

dayjs.extend(require("dayjs/plugin/isSameOrAfter"));
dayjs.extend(require("dayjs/plugin/isSameOrBefore"));

export const ExtracurricularDetailModal = ({ id, isVisible, isLoading, onLoading, onClose }) => {
	const { notification } = App.useApp();
	const studentColumns = [
		{
			title: "No",
			dataIndex: "order",
			fixed: "left",
			key: "order",
			width: 36,
		},
		{
			title: "NISN",
			dataIndex: "number",
			key: "number",
			width: 100,
			fixed: "left",
			render: (text, record) => {
				return (
					<Typography.Text
						style={{
							fontFamily: "monospace",
							fontWeight: "bold",
							whiteSpace: "nowrap",
						}}
						copyable>
						{record.number}
					</Typography.Text>
				);
			},
		},
		{
			title: "Nama",
			dataIndex: "name",
			key: "name",
			fixed: "left",
		},
		{
			title: "Aksi",
			dataIndex: "actions",
			key: "actions",
			align: "right",
			width: 24,
		},
	];

	const [extracurricular, setExtracurricular] = React.useState(null);
	const [isTeacherAssignVisible, setTeacherAssignVisible] = React.useState(false);
	const [isStudentEnrollVisible, setStudentEnrollVisible] = React.useState(false);

	const [semesterTimes, setSemesterTimes] = React.useState([]);
	const [selectedMonth, setSelectedMonth] = React.useState(null);

	const [columns, setColumns] = React.useState(studentColumns);

	// eslint-disable-next-line no-unused-vars
	const [students, setStudents] = React.useState([]);
	const [mappedStudents, setMappedStudents] = React.useState([]);

	const [mode, setMode] = React.useState("student");

	const [selectedStudentIds, setSelectedStudentIds] = React.useState([]);

	const [isReportExtracurricularVisible, setReportExtracurricularVisible] = React.useState(false);
	const [event, setEvent] = React.useState(null);

	useEffect(() => {
		if (id) {
			fetchExtracurricular();
		} else {
			setExtracurricular(null);
		}
		setMode("");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	useEffect(() => {
		if (extracurricular) {
			updateMappedItems();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [extracurricular]);

	useEffect(() => {
		setColumns(studentColumns);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mode]);

	const updateMappedItems = () => {
		const students = extracurricular.students;

		setStudents(students);

		const mappedStudents = students
			.sort((a, b) => ("" + a.name).localeCompare(b.name))
			.map((student, index) => {
				return {
					key: student._id,
					order: index + 1,
					number: student.number,
					name: student.name,
					actions: (
						<Space>
							<Dropdown
								trigger={["click"]}
								menu={{
									onClick: (e) => {
										if (e.key === "kick") {
											handleUnenrollStudent(student._id);
										}
									},
									items: [
										{
											key: "kick",
											label: "Keluarkan",
											icon: <CloseOutlined />,
											danger: true,
										},
									],
								}}
								placement="bottomRight"
								arrow>
								<Button type="default" shape="circle" size="small" icon={<MoreOutlined />} />
							</Dropdown>
						</Space>
					),
				};
			});

		setMappedStudents(mappedStudents);
	};

	const fetchExtracurricular = () => {
		onLoading(true);
		requestShowExtracurricular(id)
			.then((response) => {
				const extracurricular = response.data.data;

				setExtracurricular(extracurricular);

				const events = extracurricular.events ?? [];
				const semesters = extracurricular.year.semesters;

				const semesterTimes = [];

				// group events by time and semesters
				semesters.forEach((semester) => {
					events.forEach((event) => {
						if (
							dayjs(event.startedAt).toDate() >= dayjs(semester.startedAt).toDate() &&
							dayjs(event.startedAt).toDate() <= dayjs(semester.endedAt).toDate()
						) {
							// get day from event
							const day = dayjs(event.startedAt).day();
							// get time from event
							const startedAt = dayjs(event.startedAt).format("HH:mm");
							const endedAt = dayjs(event.endedAt).format("HH:mm");

							// concat
							const time = `${day}-${startedAt}-${endedAt}`;

							// group by day and time
							if (!semesterTimes[semester._id]) {
								semesterTimes[semester._id] = [];
							}
							if (
								!semesterTimes[semester._id]
									.map((s) => `${s.day}-${s.startedAt}-${s.endedAt}`)
									.includes(time)
							) {
								semesterTimes[semester._id].push({
									day: day,
									startedAt: startedAt,
									endedAt: endedAt,
								});
							}
						}
					});
				});

				setSemesterTimes(semesterTimes);
			})
			.catch((error) => {})
			.finally(() => {
				onLoading(false);
			});
	};

	const handleUnenrollStudent = (studentId) => {
		onLoading(true);
		requestUnenrollStudentsFromExtracurricular(extracurricular._id, {
			studentIds: [studentId],
		})
			.then((response) => {
				fetchExtracurricular();
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				onLoading(false);
			});
	};

	const handleUnassignTeacherButton = (id, teacherId) => {
		onLoading(true);
		requestUnassignTeacherFromExtracurricular(id, { teacherIds: [teacherId] })
			.then((response) => {
				fetchExtracurricular();
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				onLoading(false);
			});
	};

	const handleClose = () => {
		// form.resetFields();
		setExtracurricular(null);
		setMappedStudents([]);
		onClose();
	};

	const handleEnrollStudentButton = () => {
		setStudentEnrollVisible(true);
	};

	const handleEnrollStudentClose = () => {
		setStudentEnrollVisible(false);
	};

	const handleEnrollStudentSuccess = () => {
		setStudentEnrollVisible(false);
		fetchExtracurricular();
	};

	const handleAssignTeacherButton = () => {
		setTeacherAssignVisible(true);
	};

	const handleAssignTeacherClose = () => {
		setTeacherAssignVisible(false);
	};

	const handleAssignTeacherSuccess = () => {
		setTeacherAssignVisible(false);
		fetchExtracurricular();
	};

	const handleModeChange = (value) => {
		setMode(value);
	};

	const handleSubmit = (semesterId, values) => {
		onLoading(true);
		if (values.remaining) {
			values.mode = "REMAINING";
		}
		values.type = "EXTRA";
		values.referenceId = extracurricular._id;
		values.semesterId = semesterId;
		values.times = values.times.map((time) => {
			return {
				day: time.day,
				startedAt: dayjs(time.time[0]).format("HH:mm"),
				endedAt: dayjs(time.time[1]).format("HH:mm"),
			};
		});

		requestScheduleExtracurricular(extracurricular._id, values)
			.then((response) => {
				notification["success"]({
					message: "Good job!",
					description: response.data.message,
				});
				fetchExtracurricular();
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				onLoading(false);
			});
	};

	const handleReportButton = (event) => {
		setEvent(event);
		setReportExtracurricularVisible(true);
	};

	const handleOnReportSuccess = () => {
		fetchExtracurricular();
		setEvent(null);
		setReportExtracurricularVisible(false);
	};

	const actionMenus = [];

	const actionMenuProps = {
		items: actionMenus,
		onClick: (e) => {},
	};

	return (
		<>
			<ExtracurricularTeacherAssignFormModal
				isVisible={isTeacherAssignVisible}
				isLoading={isLoading}
				onLoading={onLoading}
				onClose={handleAssignTeacherClose}
				onSuccess={handleAssignTeacherSuccess}
				extracurricular={extracurricular}
			/>
			<ExtracurricularStudentEnrollFormModal
				isVisible={isStudentEnrollVisible}
				isLoading={isLoading}
				onLoading={onLoading}
				onClose={handleEnrollStudentClose}
				onSuccess={handleEnrollStudentSuccess}
				extracurricular={extracurricular}
			/>
			<ExtracurricularReportFormModal
				isVisible={isReportExtracurricularVisible}
				onClose={() => {
					setReportExtracurricularVisible(false);
					setEvent(null);
				}}
				onSuccess={handleOnReportSuccess}
				extracurricular={extracurricular}
				event={event}
			/>
			<Modal
				maskClosable={false}
				width={"calc(100% - 64px)"}
				style={{
					top: 32,
				}}
				title={extracurricular ? `${extracurricular.name}` : "Detail Ekskul"}
				open={isVisible}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Tutup
					</Button>,
				]}>
				<Spin spinning={isLoading}>
					{extracurricular && (
						<Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
							<Col lg={16} md={24}>
								<Space
									direction="vertical"
									size="middle"
									className="layout-popup"
									style={{ width: "100%" }}>
									<Card title="Detail" size="small">
										<Descriptions layout="vertical" column={2}>
											<Descriptions.Item label="Nama">{extracurricular.name}</Descriptions.Item>
											<Descriptions.Item label="Tahun">
												{extracurricular.year.name}
											</Descriptions.Item>
											<Descriptions.Item label="Deskripsi">
												{extracurricular.description}
											</Descriptions.Item>
											<Descriptions.Item label="Penanggungjawab">
												{/* wrap */}
												<Space wrap>
													{extracurricular.teachers.length > 0 &&
														extracurricular.teachers.map((teacher, index) => {
															return (
																<span key={index}>
																	{teacher.name}
																	<Popconfirm
																		placement="topRight"
																		title={`Anda yakin akan membebastugaskan ${teacher.name}?`}
																		onConfirm={(e) =>
																			handleUnassignTeacherButton(extracurricular._id, teacher._id)
																		}
																		okText="Yes"
																		cancelText="No">
																		<Button
																			type="link"
																			shape="default"
																			size="small"
																			icon={<CloseCircleFilled />}
																			danger
																		/>
																	</Popconfirm>
																</span>
															);
														})}
													<Button
														type="dashed"
														size="small"
														onClick={() => handleAssignTeacherButton()}>
														<PlusCircleFilled /> Tambah
													</Button>
												</Space>
											</Descriptions.Item>
										</Descriptions>
									</Card>
									<Card
										title={
											<div
												style={{
													display: "flex",
													justifyContent: "space-between",
													alignItems: "center",
													gap: 8,
												}}>
												<span>Siswa</span>
												<Space wrap>
													<Radio.Group
														size="small"
														value={mode}
														onChange={(e) => handleModeChange(e.target.value)}
														style={{ float: "right" }}>
														<Radio.Button value="student">Siswa</Radio.Button>
													</Radio.Group>
													{selectedStudentIds.length > 0 ? (
														<Dropdown.Button
															type="default"
															size="small"
															menu={actionMenuProps}
															trigger={["click"]}>
															<CheckCircleFilled /> {selectedStudentIds.length} Dipilih
														</Dropdown.Button>
													) : (
														<Button type="default" size="small" onClick={handleEnrollStudentButton}>
															<PlusCircleFilled /> Tambah
														</Button>
													)}
												</Space>
											</div>
										}
										size="small">
										<Table
											size="small"
											columns={columns}
											dataSource={mappedStudents}
											loading={isLoading}
											bordered
											pagination={{
												position: "bottomRight",
												pageSizeOptions: ["10", "20", "50", "100"],
												showSizeChanger: true,
												defaultPageSize: 100,
												locale: { items_per_page: "" },
											}}
											scroll={{ x: "max-content" }}
											rowSelection={{
												type: "checkbox",
												...{
													onChange: (selectedRowKeys, selectedRows) => {
														setSelectedStudentIds(selectedRowKeys.map((key) => key.toString()));
													},
												},
											}}
										/>
									</Card>
								</Space>
							</Col>
							<Col lg={8} md={24}>
								<Space
									direction="vertical"
									size="middle"
									className="layout-popup"
									style={{ width: "100%" }}>
									<Card title="Sinkronisasi Jadwal" size="small">
										<Tabs
											type="line"
											size="small"
											items={extracurricular.year.semesters.map((semester) => {
												return {
													label: `${semester.name}`,
													key: semester._id,
													children: (
														<SemesterForm
															onSubmit={(values) => {
																handleSubmit(semester._id, values);
															}}
															times={semesterTimes[semester._id]}
														/>
													),
												};
											})}
										/>
									</Card>
									<Card
										title={
											<Row justify="space-between">
												Pertemuan{" "}
												<DatePicker
													size="small"
													picker="month"
													placeholder="Pilih bulan"
													onChange={(v) => {
														setSelectedMonth(v);
													}}
												/>
											</Row>
										}
										size="small">
										<Space style={{ width: "100%" }} direction="vertical">
											<Steps
												progressDot
												size="small"
												direction="vertical"
												current={
													extracurricular.events?.filter(
														(event) =>
															dayjs(event.startedAt).format("MM-YYYY") ===
																dayjs(selectedMonth).format("MM-YYYY") || selectedMonth === null
													).length - 1
												}>
												{extracurricular.events &&
													extracurricular.events
														.filter(
															(event) =>
																dayjs(event.startedAt).format("MM-YYYY") ===
																	dayjs(selectedMonth).format("MM-YYYY") || selectedMonth === null
														)
														.map((event) => (
															<Steps.Step
																key={event._id}
																title={dayjs(event.startedAt).format("dddd, D MMMM YYYY")}
																description={
																	<span>
																		{dayjs(event.startedAt).format("HH:mm") +
																			" - " +
																			dayjs(event.endedAt).format("HH:mm")}
																		{dayjs(event.startedAt).toDate() < dayjs().toDate() && (
																			<Button
																				type="dashed"
																				style={{ marginLeft: 4 }}
																				size="small"
																				onClick={() => {
																					handleReportButton(event);
																				}}>
																				Laporan
																			</Button>
																		)}
																	</span>
																}
															/>
														))}
											</Steps>
										</Space>
									</Card>
								</Space>
							</Col>
						</Row>
					)}
				</Spin>
			</Modal>
		</>
	);
};

export default ExtracurricularDetailModal;

export function SemesterForm({ times, onSubmit }) {
	const [form] = Form.useForm();

	const handleSubmit = () => {
		form.validateFields().then((values) => {
			onSubmit(values);
		});
	};

	useEffect(() => {
		if (times) {
			form.setFieldsValue({
				times: times.map((time) => {
					return {
						day: time.day.toString(),
						time: [dayjs(time.startedAt, "HH:mm"), dayjs(time.endedAt, "HH:mm")],
					};
				}),
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [times]);

	return (
		<Form form={form} layout="vertical" requiredMark="optional">
			<Form.List name="times" label="Bahan" style={{ marginTop: 0, marginBottom: 12 }}>
				{(times, { add, remove }) => (
					<>
						{times.map(({ key, name, ...restField }, itemIndex) => (
							<Row gutter={8} key={key} align="baseline">
								<Col span={8}>
									<Form.Item
										required
										style={{ marginTop: 0, marginBottom: 12 }}
										{...restField}
										name={[name, "day"]}
										rules={[
											{
												required: true,
												message: "Nama harus diisi!",
											},
										]}>
										<Select placeholder="Hari">
											<Select.Option value="0">Ahad</Select.Option>
											<Select.Option value="1">Senin</Select.Option>
											<Select.Option value="2">Selasa</Select.Option>
											<Select.Option value="3">Rabu</Select.Option>
											<Select.Option value="4">Kamis</Select.Option>
											<Select.Option value="5">Jumat</Select.Option>
											<Select.Option value="6">Sabtu</Select.Option>
										</Select>
									</Form.Item>
								</Col>
								<Col span={16}>
									<Form.Item requiredMark style={{ marginTop: 0, marginBottom: 0 }} {...restField}>
										<Input.Group compact>
											<Form.Item
												requiredMark
												name={[name, "time"]}
												style={{
													width: "calc(100% - 32px)",
													marginTop: 0,
													marginBottom: 0,
												}}
												rules={[
													{
														required: true,
														message: "Waktu harus diisi ya!",
													},
												]}>
												<TimePicker.RangePicker
													format={"HH:mm"}
													style={{
														width: "100%",
														marginTop: 0,
														marginBottom: 0,
														borderTopRightRadius: 0,
														borderBottomRightRadius: 0,
													}}
												/>
											</Form.Item>
											<Button
												icon={<DeleteOutlined />}
												onClick={() => remove(name)}
												danger></Button>
										</Input.Group>
									</Form.Item>
								</Col>
							</Row>
						))}
						<Form.Item style={{ marginTop: 0, marginBottom: 12 }}>
							<Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
								Tambah Jam
							</Button>
						</Form.Item>
					</>
				)}
			</Form.List>
			<Form.Item name="remaining" valuePropName="checked" initialValue={true}>
				<Checkbox value={"remaining"}>Ganti sisa jadwal saja (mulai hari ini)</Checkbox>
			</Form.Item>
			<Popconfirm
				placement="topRight"
				title={`Semua data yang berkaitan (termasuk laporan) dengan jadwal yang terhapus akan ikut terhapus. Apakah anda yakin?`}
				onConfirm={handleSubmit}
				okText="Yes"
				cancelText="No">
				<Button type="primary" style={{ width: "100%" }}>
					Terapkan
				</Button>
			</Popconfirm>
		</Form>
	);
}
