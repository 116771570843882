import React, { useState } from "react";

export type User = {
  _id: string;
  name: string;
  email: string;
  phone: string;
  password: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  accountVerifiedAt: string;
  resetToken: string;
  resetTokenExpiredAt: string;
  permissions: any[];
  role: string;
  notifications: any[];
  preference: Preference;
  tokens: any[];
  deleted: boolean;
  school: School;
  year: Year;
};
type Year = {
  _id: string;
  schoolId: string;
  name: string;
  startedAt: string;
  endedAt: string;
  semesters: Semester[];
  createdAt: string;
  updatedAt: string;
  __v: number;
  deleted: boolean;
};
type Semester = {
  name: string;
  startedAt: string;
  endedAt: string;
  _id: string;
  status: string;
};
type School = {
  name: string;
  address: string;
  logo: Logo;
};
type Logo = {
  _id: string;
  url: string;
};
type Preference = {
  notification: Notification;
};
type Notification = {
  authLogin: AuthLogin;
  authLogout: AuthLogin;
  authResetPassword: AuthLogin;
};
type AuthLogin = {
  push: boolean;
  email: boolean;
};

const AppContext = React.createContext({
  isLoggedIn: false,
  role: "",
  year: {} as Year,
  user: {} as User,
  login: (user: User) => {},
  logout: () => {},
  setYear: (year: Year) => {},
  setUser: (user: User) => {},
  changeRole: (role: string) => {},
});

export const AppContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const initialLoggedIn: boolean = localStorage.getItem("logged_in") === "true";
  const initialRole: string | null = localStorage.getItem("role");
  const initialUser: string | null = localStorage.getItem("user");
  const initialYear: string | null = localStorage.getItem("year");
  const [isLoggedIn, setLoggedIn] = useState(initialLoggedIn);
  const [role, setRole] = useState<string | null>(initialRole);
  const [year, setYear] = useState<Year>(
    initialYear ? JSON.parse(initialYear) : {},
  );
  const [user, setUser] = useState<User>(
    initialUser ? JSON.parse(initialUser) : {},
  );

  const loginHandler = (user: User) => {
    setLoggedIn(true);
    setRole(user.role);
    localStorage.setItem("logged_in", "true");
    localStorage.setItem("role", user.role);
    localStorage.setItem("user", JSON.stringify(user));
  };

  const logoutHandler = () => {
    setLoggedIn(false);
    localStorage.removeItem("logged_in");
    localStorage.removeItem("role");
    localStorage.removeItem("year");
    localStorage.removeItem("user");
  };

  const setYearHandler = (year: Year) => {
    setYear(year);
    localStorage.setItem("year", JSON.stringify(year));
  };

  const setUserHandler = (user: User) => {
    setUser(user);
    localStorage.setItem("user", JSON.stringify(user));
  };

  const changeRoleHandler = (role: string) => {
    setRole(role);
    localStorage.setItem("role", role);
  };

  const contextValue = {
    isLoggedIn: isLoggedIn,
    role: role || "",
    year: year,
    user: user,
    login: loginHandler,
    logout: logoutHandler,
    setYear: setYearHandler,
    setUser: setUserHandler,
    changeRole: changeRoleHandler,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

export default AppContext;
