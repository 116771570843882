/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { ReconciliationFilled, PlusCircleFilled, MoreOutlined } from "@ant-design/icons";
import { HiOutlineEye, HiOutlineNoSymbol, HiOutlinePaperAirplane } from "react-icons/hi2";
import { App, Button, Card, Col, Dropdown, Row, Space, Table, Tag, Typography } from "antd";
import { useEffect, useLayoutEffect, useState } from "react";
import InvoiceFormModal from "./components/InvoiceFormModal";
import { requestGetInvoiceList, requestUpdateInvoiceStatus } from "../../../services/admin.service";
import dayjs from "dayjs";
import InvoiceGenerateFormModal from "./components/InvoiceGenerateFormModal";
import InvoiceDetailModal from "./components/InvoiceDetailModal";
import InvoicePayamentFormModal from "./components/InvoicePaymentFormModal";
import SearchInput from "../../../components/SearchInput";
import DateRangeFilter from "../../../components/DateRangeFilter";
import { useSearchParams } from "react-router-dom";

const columns = [
	{
		title: "Nomor",
		dataIndex: "number",
		key: "number",
		render: (number) => (
			<Typography.Title
				style={{
					margin: 0,
				}}
				level={5}>
				{number}{" "}
			</Typography.Title>
		),
	},
	{
		title: "Tanggal",
		dataIndex: "issuedAt",
		key: "issuedAt",
		render: (date) => dayjs(date).format("DD MMMM YYYY"),
		sorter: true,
	},
	{
		title: "Status",
		dataIndex: "status",
		key: "status",
		render: (status) => {
			const readableStatus = {
				DRAFT: "DRAF",
				PARTIAL: "SEBAGIAN",
				SENT: "TERKIRIM",
				PAID: "TERBAYAR",
				VOID: "TIDAK SAH",
			};
			const color = {
				DRAFT: "error",
				PARTIAL: "warning",
				SENT: "processing",
				PAID: "success",
				VOID: "default",
			};
			return <Tag color={color[status]}>{readableStatus[status]}</Tag>;
		},
		filters: [
			{
				text: "Draf",
				value: "DRAFT",
			},
			{
				text: "Terkirim",
				value: "SENT",
			},
			{
				text: "Sebagian",
				value: "PARTIAL",
			},
			{
				text: "Terbayar",
				value: "PAID",
			},
			{
				text: "Tidak Sah",
				value: "VOID",
			},
		],
	},
	{
		title: "Nama Siswa",
		dataIndex: "student",
		key: "student",
		render: (student, record) => (
			<Space direction="vertical" size="small">
				{`${student.name} (${student.number})`}
				<Tag color="default">{`${student.classroom?.name ?? "N/a"} (${
					record.year?.name ?? "N/a"
				})`}</Tag>
			</Space>
		),
	},
	// {
	// 	title: "Item",
	// 	dataIndex: "items",
	// 	key: "items",
	// 	render: (items) => (
	// 		<Tooltip title={`${items.map((i) => i.fee.name).join(", ")}`}>
	// 			{items
	// 				.slice(0, 2)
	// 				.map((i) => i.fee.name)
	// 				.join(", ")}
	// 			{items.length > 2 ? "..." : ""}
	// 		</Tooltip>
	// 	),
	// },
	{
		title: "Total",
		dataIndex: "total",
		key: "total",
		render: (total) =>
			Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(total),
	},
	{
		title: "Aksi",
		dataIndex: "actions",
		key: "actions",
		align: "right",
	},
];

const InvoicePage = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { message, notification, modal } = App.useApp();

	useLayoutEffect(() => {
		document.title = "Ruang Admin | Keuangan | Tagihan";
	}, []);

	// SEARCH, FILTER, PAGINATION
	const [keyword, setKeyword] = useState("");
	const [filter, setFilter] = useState({
		startedAt: dayjs().startOf("month").format("YYYY-MM-DD"),
		endedAt: dayjs().endOf("month").format("YYYY-MM-DD"),
	});
	const [sorter, setSorter] = useState({
		issuedAt: "desc",
	});
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);

	const handleSearch = (value) => {
		setKeyword(value.trim());
	};

	const handlePaginate = (pagination, filters, sorter) => {
		setPage(pagination.current);
		setLimit(pagination.pageSize);

		// UPDATE FILTER
		if (filters) {
			const additionalFilter = {};
			Object.keys(filters).forEach((key) => {
				if (filters[key]) {
					additionalFilter[key] = filters[key].join("|");
				} else {
					additionalFilter[key] = "";
				}
			});

			setFilter({
				...filter,
				...additionalFilter,
			});
		} else {
			const tempFilter = {};
			// only allow startedAt and endedAt
			Object.keys(filter).forEach((key) => {
				if (key === "startedAt" || key === "endedAt") {
					tempFilter[key] = filter[key];
				}
			});

			setFilter(tempFilter);
		}

		// UPDATE SORTER
		if (sorter && sorter.field) {
			setSorter({
				[sorter.field]: sorter.order === "ascend" ? "asc" : "desc",
			});
		} else {
			setSorter({
				_id: "desc",
			});
		}
	};
	// SEARCH, FILTER, PAGINATION

	const [invoices, setInvoices] = useState([]);
	const [mappedInvoices, setMappedInvoices] = useState([]);
	const [isLoading, setLoading] = useState(false);

	const [invoice, setInvoice] = useState(null);
	const [isInvoiceGenerateFormVisible, setInvoiceGenerateFormVisible] = useState(false);
	const [isInvoiceFormVisible, setInvoiceFormVisible] = useState(false);
	const [isInvoiceDetailVisible, setInvoiceDetailVisible] = useState(false);
	const [isPaymentFormVisible, setPaymentFormVisible] = useState(false);
	const [selectedInvoiceIds, setSelectedInvoiceIds] = useState([]);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);

	useEffect(() => {
		setSearchParams({ keyword: keyword, page: page, limit: limit });

		fetchInvoiceList();
		setSelectedInvoiceIds([]);
		setSelectedRowKeys([]);
	}, [keyword, filter, page, limit]);

	useEffect(() => {
		if (searchParams.has("keyword")) {
			setKeyword(searchParams.get("keyword"));
		}
		if (searchParams.has("page")) {
			setPage(parseInt(searchParams.get("page")));
		}
		if (searchParams.has("limit")) {
			setLimit(parseInt(searchParams.get("limit")));
		}
	}, []);

	const fetchInvoiceList = () => {
		setLoading(true);

		let tempFilter = "";
		if (filter) {
			tempFilter = Object.keys(filter)
				.map((key) => `${key}:${filter[key]}`)
				.filter(
					(f) => f.split(":")[1] !== "" && f.split(":")[1] !== undefined && f.split(":")[1] !== null
				)
				.join(",");
		}

		let tempSorter = "";
		if (sorter) {
			tempSorter = Object.keys(sorter)
				.map((key) => `${key}:${sorter[key]}`)
				.filter(
					(s) => s.split(":")[1] !== "" && s.split(":")[1] !== undefined && s.split(":")[1] !== null
				)
				.join(",");
		}

		requestGetInvoiceList({
			page: page,
			limit: limit,
			order: tempSorter ?? "_id:desc",
			keyword: keyword ?? "",
			filter: tempFilter ?? "",
		})
			.then((response) => {
				setTotal(response.data.pagination.total);
				setInvoices(response.data.data);
				updateMappedInvoice(response.data.data);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleUpdateStatusButton = (invoice, status) => {
		const statusFrom = invoice.status;
		const readableStatus = {
			SENT: "TERKIRIM",
			PARTIAL: "SEBAGIAN",
			PAID: "TERBAYAR",
			VOID: "TIDAK SAH",
		};

		modal.confirm({
			title: "Konfirmasi",
			content: `Apakah Anda yakin ingin mengubah status tagihan ${invoice.number} menjadi ${readableStatus[status]}?`,
			okText: "Ya",
			cancelText: "Tidak",
			onOk: () => {
				setLoading(true);
				requestUpdateInvoiceStatus({
					ids: [invoice._id],
					status: status,
				})
					.then((response) => {
						notification["success"]({
							message: "Good job!",
							description: response.data.message,
						});
						fetchInvoiceList();

						const newSelectedRowKeys = selectedRowKeys.filter((k) => {
							if (selectedInvoiceIds[statusFrom].includes(k)) {
								return false;
							}
							return true;
						});
						onSelectChange(newSelectedRowKeys);
					})
					.catch((error) => {
						if (error.response && error.response.data) {
							notification["error"]({
								message: "Kesalahan!",
								description: error.response.data.message,
							});
						}
					})
					.finally(() => {
						setLoading(false);
					});
			},
		});
	};

	const updateMappedInvoice = (data) => {
		setMappedInvoices(
			data.map((d) => {
				const otherButtons = [
					{
						key: "show",
						label: "Lihat",
						icon: <HiOutlineEye />,
					},
				];

				if (d.status === "DRAFT") {
					otherButtons.push({
						key: "send",
						label: "Kirim",
						icon: <HiOutlinePaperAirplane />,
					});
				} else if (d.status === "SENT" || d.status === "PARTIAL") {
					// otherButtons.push({
					// 	key: "accept",
					// 	label: "Tandai Terbayar",
					// 	icon: <HiOutlineShieldCheck />,
					// });
				}

				if (d.status !== "VOID") {
					otherButtons.push(
						...[
							{
								type: "divider",
							},
							{
								key: "void",
								label: "Batal",
								icon: <HiOutlineNoSymbol />,
								danger: true,
							},
						]
					);
				}
				return {
					key: d._id,
					number: d.number,
					issuedAt: d.issuedAt,
					status: d.status,
					student: d.student,
					year: d.year,
					items: d.items,
					total: d.total,
					actions: (
						<Space>
							<Dropdown
								trigger={["click"]}
								menu={{
									onClick: (e) => {
										if (e.key === "show") {
											handleShowButton(d);
										} else if (e.key === "send") {
											handleUpdateStatusButton(d, "SENT");
										} else if (e.key === "accept") {
											handleUpdateStatusButton(d, "PAID");
										} else if (e.key === "void") {
											handleUpdateStatusButton(d, "VOID");
										}
									},
									items: otherButtons,
								}}
								placement="bottomRight"
								arrow>
								<Button type="default" shape="circle" size="small" icon={<MoreOutlined />} />
							</Dropdown>
						</Space>
					),
				};
			})
		);
	};

	const handleGenerateButton = () => {
		setInvoiceGenerateFormVisible(true);
	};

	const handleAddButton = () => {
		setInvoiceFormVisible(true);
	};

	const handleShowButton = (invoice) => {
		setInvoice(invoice);
		setInvoiceDetailVisible(true);
	};

	const handleOnCloseInvoiceDetail = () => {
		setInvoice(null);
		setInvoiceDetailVisible(false);
	};

	const handleOnCloseInvoiceForm = () => {
		setInvoice(null);
		setInvoiceFormVisible(false);
	};

	const handleOnSuccessInvoiceForm = () => {
		setInvoice(null);
		setInvoiceFormVisible(false);
		fetchInvoiceList();
	};

	const onSelectChange = (newSelectedRowKeys) => {
		setSelectedRowKeys(newSelectedRowKeys);
		// group per status
		const tempSelectedInvoiceIds = newSelectedRowKeys.reduce((r, a) => {
			r[invoices.find((i) => i._id === a).status] = [
				...(r[invoices.find((i) => i._id === a).status] || []),
				a,
			];
			return r;
		}, {});

		setSelectedInvoiceIds(tempSelectedInvoiceIds);
	};

	const rowSelection = {
		selectedRowKeys: selectedRowKeys,
		onChange: onSelectChange,
		getCheckboxProps: (record) => ({
			disabled: record.status === "VOID",
		}),
		// selections: [
		// 	{
		// 		key: "DRAFT",
		// 		text: "DRAFT",
		// 		onSelect: (changeableRowKeys) => {
		// 			let newSelectedRowKeys = [];
		// 			newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
		// 				if (invoices[index].status !== "DRAFT") {
		// 					return false;
		// 				}
		// 				return true;
		// 			});
		// 			setSelectedInvoiceIds(newSelectedRowKeys);
		// 		},
		// 	},
		// 	{
		// 		key: "SENT",
		// 		text: "TERKIRIM",
		// 		onSelect: (changeableRowKeys) => {
		// 			let newSelectedRowKeys = [];
		// 			newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
		// 				if (invoices[index].status !== "SENT") {
		// 					return false;
		// 				}
		// 				return true;
		// 			});
		// 			setSelectedInvoiceIds(newSelectedRowKeys);
		// 		},
		// 	},
		// 	{
		// 		key: "PAID",
		// 		text: "TERBAYAR",
		// 		onSelect: (changeableRowKeys) => {
		// 			let newSelectedRowKeys = [];
		// 			newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
		// 				if (invoices[index].status !== "PAID") {
		// 					return false;
		// 				}
		// 				return true;
		// 			});
		// 			setSelectedInvoiceIds(newSelectedRowKeys);
		// 		},
		// 	},
		// ],
	};

	const bulkUpdateStatus = (statusFrom, statusTo) => {
		const readableStatus = {
			SENT: "TERKIRIM",
			PARTIAL: "SEBAGIAN",
			PAID: "TERBAYAR",
			VOID: "TIDAK SAH",
		};

		modal.confirm({
			title: "Konfirmasi",
			content: `Apakah Anda yakin ingin mengubah status tagihan (${selectedInvoiceIds[statusFrom].length}) menjadi ${readableStatus[statusTo]}? Ingat, mengubah status dengan aksi ini hanya menandai status tagihan, namun tidak dengan riwayat pembayaran. Tagihan ini tidak akan memiliki detail pembayaran. Sebaiknya Anda menambah pembayaran sehingga tagihan memiliki riwayat pembayaran yang lengkap dan secara otomatis akan menandai tagihan sebagai terbayar.`,
			okText: "Ya",
			cancelText: "Tidak",
			onOk: () => {
				setLoading(true);
				requestUpdateInvoiceStatus({
					ids: selectedInvoiceIds[statusFrom],
					status: statusTo,
				})
					.then((response) => {
						notification["success"]({
							message: "Good job!",
							description: response.data.message,
						});
						fetchInvoiceList();

						// uncheck where keys are in selectedInvoiceIds[statusFrom]
						const newSelectedRowKeys = selectedRowKeys.filter((k) => {
							if (selectedInvoiceIds[statusFrom].includes(k)) {
								return false;
							}
							return true;
						});
						onSelectChange(newSelectedRowKeys);
					})
					.catch((error) => {
						if (error.response && error.response.data) {
							notification["error"]({
								message: "Kesalahan!",
								description: error.response.data.message,
							});
						}
					})
					.finally(() => {
						setLoading(false);
					});
			},
		});
	};

	const onMenuClick = (status, key) => {
		if (key === "send") {
			bulkUpdateStatus(status, "SENT");
		} else if (key === "accept") {
			bulkUpdateStatus(status, "PAID");
		} else if (key === "void") {
			bulkUpdateStatus(status, "VOID");
		}
	};

	const handlePayButton = (invoice) => {
		setInvoice(invoice);
		setPaymentFormVisible(true);
	};

	const handleOnSuccessPaymentForm = () => {
		setInvoiceDetailVisible(false);
		setPaymentFormVisible(false);
		setInvoice(null);
		fetchInvoiceList();
	};

	return (
		<>
			<InvoiceGenerateFormModal
				isLoading={isLoading}
				isVisible={isInvoiceGenerateFormVisible}
				onLoading={(v) => setLoading(v)}
				onClose={() => setInvoiceGenerateFormVisible(false)}
				onSuccess={() => setInvoiceGenerateFormVisible(false)}
			/>
			<InvoiceFormModal
				isLoading={isLoading}
				isVisible={isInvoiceFormVisible}
				onLoading={(v) => setLoading(v)}
				invoice={invoice}
				onClose={handleOnCloseInvoiceForm}
				onSuccess={handleOnSuccessInvoiceForm}
			/>
			<InvoiceDetailModal
				isVisible={isInvoiceDetailVisible}
				invoice={invoice}
				onClose={handleOnCloseInvoiceDetail}
				onPay={handlePayButton}
			/>
			<InvoicePayamentFormModal
				isLoading={isLoading}
				isVisible={isPaymentFormVisible}
				onLoading={(v) => setLoading(v)}
				invoice={invoice}
				onClose={() => setPaymentFormVisible(false)}
				onSuccess={() => handleOnSuccessPaymentForm()}
			/>
			<Card
				bordered={false}
				title="Daftar Tagihan"
				extra={
					<Space>
						{(!selectedInvoiceIds || Object.keys(selectedInvoiceIds).length === 0) && (
							<>
								<SearchInput placeholder="Nomor tagihan, nama atau NISN" onSearch={handleSearch} />
								<DateRangeFilter
									onOk={(start, end) => {
										setFilter({
											startedAt: start,
											endedAt: end,
										});
									}}
									startedAt={filter.startedAt}
									endedAt={filter.endedAt}
								/>
							</>
						)}
						{selectedInvoiceIds["DRAFT"] && selectedInvoiceIds["DRAFT"].length > 0 && (
							<Dropdown.Button
								menu={{
									items: [
										{
											key: "send",
											label: "Kirim Tagihan",
											icon: <HiOutlinePaperAirplane />,
										},
										{
											key: "void",
											label: "Batalkan Tagihan",
											icon: <HiOutlineNoSymbol />,
											danger: true,
										},
									],
									onClick: (e) => {
										onMenuClick("DRAFT", e.key);
									},
								}}>
								Ubah Draf ({selectedInvoiceIds["DRAFT"].length})
							</Dropdown.Button>
						)}
						{selectedInvoiceIds["SENT"] && selectedInvoiceIds["SENT"].length > 0 && (
							<Dropdown.Button
								menu={{
									items: [
										// {
										// 	key: "accept",
										// 	label: "Tandai Terbayar",
										// 	icon: <HiOutlineShieldCheck />,
										// },
										{
											key: "void",
											label: "Batalkan Tagihan",
											icon: <HiOutlineNoSymbol />,
											danger: true,
										},
									],
									onClick: (e) => {
										onMenuClick("SENT", e.key);
									},
								}}>
								Ubah Terkirim ({selectedInvoiceIds["SENT"].length})
							</Dropdown.Button>
						)}
						{selectedInvoiceIds["PARTIAL"] && selectedInvoiceIds["PARTIAL"].length > 0 && (
							<Dropdown.Button
								menu={{
									items: [
										// {
										// 	key: "accept",
										// 	label: "Tandai Terbayar",
										// 	icon: <HiOutlineShieldCheck />,
										// },
										{
											key: "void",
											label: "Batalkan Tagihan",
											icon: <HiOutlineNoSymbol />,
											danger: true,
										},
									],
									onClick: (e) => {
										onMenuClick("PARTIAL", e.key);
									},
								}}>
								Ubah Parsial ({selectedInvoiceIds["PARTIAL"].length})
							</Dropdown.Button>
						)}
						{selectedInvoiceIds["PAID"] && selectedInvoiceIds["PAID"].length > 0 && (
							<Dropdown.Button
								menu={{
									items: [
										{
											key: "void",
											label: "Batalkan Tagihan",
											icon: <HiOutlineNoSymbol />,
											danger: true,
										},
									],
									onClick: (e) => {
										onMenuClick("PAID", e.key);
									},
								}}>
								Ubah Terbayar ({selectedInvoiceIds["PAID"].length})
							</Dropdown.Button>
						)}
						{(!selectedInvoiceIds || Object.keys(selectedInvoiceIds).length === 0) && (
							<>
								<Button icon={<PlusCircleFilled />} type="default" onClick={handleAddButton}>
									Tambah Manual
								</Button>
								<Button
									icon={<ReconciliationFilled />}
									type="primary"
									onClick={handleGenerateButton}>
									Buat Tagihan Masal
								</Button>
							</>
						)}
					</Space>
				}>
				<div>
					<Table
						bordered
						rowSelection={rowSelection}
						columns={columns}
						loading={isLoading}
						dataSource={mappedInvoices}
						pagination={{
							current: page,
							total: total,
							position: "bottomRight",
							pageSizeOptions: ["10", "20", "50", "100"],
							showSizeChanger: true,
							locale: { items_per_page: "item/hal" },
						}}
						onChange={handlePaginate}
					/>
				</div>
			</Card>
		</>
	);
};

export default InvoicePage;
