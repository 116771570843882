import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
	Layout,
	Button,
	Typography,
	Card,
	Form,
	Input,
	Checkbox,
	Spin,
	Row,
	Col,
	Radio,
	theme,
	Select,
	Space,
	message,
	notification,
} from "antd";
import axios from "axios";
import countryCodes from "country-codes-list";

const { Title } = Typography;
const { Footer } = Layout;
const RegisterPage = () => {
	const navigate = useNavigate();

	useLayoutEffect(() => {
		document.title = "Register";
	}, []);

	const [form] = Form.useForm();
	const phone = Form.useWatch("phone", form);
	const [isLoading, setLoading] = useState(false);

	useEffect(() => {
		form.setFieldsValue({
			countryCode: "62",
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		console.log(phone);
		if (phone && phone.length > 0) {
			if (phone.startsWith("0")) {
				form.setFieldValue("phone", phone.substring(1));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [phone]);

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				setLoading(true);

				values.phone = `+${values.countryCode}${values.phone.replace(/\D/g, "")}`;

				axios
					.post(process.env.REACT_APP_API_URL + "/auth/register", {
						name: values.name,
						email: values.email.trim().toLowerCase(),
						phone: values.phone,
						password: values.password,
						role: values.role,
						number: values.number,
					})
					.then((response) => {
						if (response.data.code === 200) {
							message.success("Berhasil daftar, silahkan login ^_^");

							navigate("/login");
						} else {
							notification.error({
								message: "Kesalahan!",
								description: response.data.message,
							});
						}
					})
					.catch((error) => {
						if (error.response !== undefined) {
							let message = error.response.data.message + ". ";
							if (error.response.data.errors !== undefined) {
								Object.keys(error.response.data.errors).forEach((key) => {
									message += `${error.response.data.errors[key]}. `;
								});
							}
							notification.error({
								message: "Kesalahan!",
								description: message,
							});
						} else {
							notification.error({
								message: "Kesalahan!",
								description: error.message,
							});
						}
					})
					.finally(() => {
						setLoading(false);
					});
			})
			.catch((error) => {});
	};

	const {
		token: { borderRadius },
	} = theme.useToken();

	return (
		<>
			{isLoading && <Spin className="container-spin" size="large" />}
			<Row type="flex" justify="center" align="middle" style={{ minHeight: "100vh" }}>
				<Col xs={24} sm={24} md={12} lg={12} xl={8}>
					<Card style={{ margin: "24px" }}>
						<Title level={4}>Daftar</Title>
						<p>Pastikan menggunakan data yang benar ya</p>
						<Form form={form} initialValues={{ remember: true }}>
							<Form.Item
								name="role"
								rules={[{ required: true, message: "Pilih role Anda" }]}
								required>
								<Radio.Group
									size="large"
									options={[
										{ label: "Pengajar", value: "TEACHER" },
										{ label: "Siswa", value: "STUDENT" },
										{ label: "Wali", value: "PARENT" },
									]}
									optionType="button"
									buttonStyle="solid"
								/>
							</Form.Item>
							<Form.Item
								name="number"
								rules={[{ required: true, message: "Masukkan nomor ID Anda" }]}>
								<Input size="large" placeholder="Nomor ID" />
							</Form.Item>
							<Form.Item name="name" rules={[{ required: true, message: "Masukkan nama Anda" }]}>
								<Input size="large" placeholder="Nama" />
							</Form.Item>
							{/* Note */}
							<Typography.Text
								type="secondary"
								style={{
									display: "block",
									marginBottom: "16px",
								}}>
								Nama yang Anda masukkan akan digunakan sebagai nama akun Anda. Nama ini bisa berbeda
								dengan data yang tertera di kartu identitas Anda.
							</Typography.Text>
							<Form.Item name="email" rules={[{ required: true, message: "Masukkan email Anda" }]}>
								<Input size="large" placeholder="Email" />
							</Form.Item>
							<Form.Item>
								<Space.Compact
									style={{
										width: "100%",
									}}>
									<Form.Item
										name="countryCode"
										noStyle
										rules={[
											{
												required: true,
												message: "Pilih kode negara",
											},
										]}>
										<Select
											size="large"
											style={{ width: 80 }}
											filterOption={(input, option) =>
												option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
											options={Object.keys(
												countryCodes.customList("countryCallingCode", "countryCode")
											).map((country) => {
												return {
													label: `+${country}`,
													value: `${country}`,
												};
											})}
										/>
									</Form.Item>
									<Form.Item
										name="phone"
										noStyle
										style={{
											flex: 1,
										}}
										rules={[
											{
												required: true,
												message: "Masukkan nomor telepon",
											},
										]}>
										<Input size="large" style={{ width: "100%" }} placeholder="No. Telepon" />
									</Form.Item>
								</Space.Compact>
							</Form.Item>
							<Typography.Text
								type="secondary"
								style={{
									display: "block",
									marginBottom: "16px",
								}}>
								Masukkan nomor telepon tanpa angka 0 di depannya.
							</Typography.Text>
							<Form.Item
								name="password"
								rules={[{ required: true, message: "Masukkan password Anda" }]}>
								<Input.Password size="large" type="password" placeholder="Password" />
							</Form.Item>
							<Form.Item>
								<Form.Item
									name="remember"
									valuePropName="checked"
									noStyle
									rules={[
										{
											required: true,
											message: "Anda harus menyetujui syarat dan ketentuan yang berlaku",
										},
									]}
									required>
									<Checkbox>
										Saya setuju{" "}
										<a href="https://smartiva.app/terms" target="_blank" rel="noreferrer">
											syarat dan ketentuan yang berlaku
										</a>
									</Checkbox>
								</Form.Item>
							</Form.Item>
							<Form.Item>
								<Button
									loading={isLoading}
									type="primary"
									onClick={handleSubmit}
									style={{
										width: "100%",
									}}
									size="large">
									Daftar
								</Button>
							</Form.Item>
							<Typography.Text
								style={{
									display: "block",
									textAlign: "center",
									marginBottom: "16px",
								}}>
								Sidah punya akun?{" "}
								<Link to="/login" className="font-bold text-dark">
									Masuk
								</Link>
							</Typography.Text>
						</Form>
						<Footer style={{ marginTop: 24, borderRadius: borderRadius }}>
							<Typography.Text>
								Copyright © {new Date().getFullYear()} Smartiva from{" "}
								<Typography.Link href="https://www.codeiva.com">Codeiva</Typography.Link>.
							</Typography.Text>
						</Footer>
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default RegisterPage;
