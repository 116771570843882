import React, { useContext, useEffect, useState } from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import {
	HiAcademicCap,
	HiBars3,
	HiBookOpen,
	HiBriefcase,
	HiCalendarDays,
	HiChartPie,
	HiDocumentChartBar,
	HiFire,
	HiMegaphone,
	HiRectangleGroup,
	HiShieldCheck,
	HiUser,
	HiWallet,
} from "react-icons/hi2";
import { Collapse, CollapseProps, Layout, Menu, Space, Tag, theme, Typography } from "antd";
import { getActiveYear as adminGetActiveYear } from "../../services/admin.service";
import { requestGetActiveYear as teacherGetActiveYear } from "../../services/teacher.service";
import logo from "../../assets/images/smartiva.png";
import { useLocation, useNavigate } from "react-router";
import AppContext from "../../store/app.context";

const { Sider } = Layout;

const siderStyle: React.CSSProperties = {
	overflow: "auto",
	height: "100vh",
	position: "fixed",
	insetInlineStart: 0,
	top: 0,
	bottom: 0,
	scrollbarWidth: "thin",
	scrollbarColor: "#F1F1F1FF #FFFFFFFF",
	scrollbarGutter: "0px",
	scrollBehavior: "smooth",
	boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
	zIndex: 10,
};

const adminMenu = [
	{
		key: "dashboard",
		icon: <HiRectangleGroup />,
		label: "Dasbor",
	},
	{
		key: "users",
		icon: <HiUser />,
		label: "Pengguna",
	},
	{
		key: "students",
		icon: <HiAcademicCap />,
		label: "Siswa",
	},
	{
		key: "teachers",
		icon: <HiBriefcase />,
		label: "Pengajar",
	},
	{
		key: "academic",
		icon: <HiBookOpen />,
		label: "Akademik",
		children: [
			{
				key: "academic/curriculums",
				// icon: <HiBookmark />,
				label: "Kurikulum",
			},
			{
				key: "academic/years",
				// icon: <HiBuildingLibrary />,
				label: "Tahun",
			},
			{
				key: "academic/classrooms",
				// icon: <HiChartBar />,
				label: "Kelas",
			},
			{
				key: "academic/lessons",
				// icon: <HiBookOpen />,
				label: "Pelajaran",
			},
		],
	},
	{
		key: "extracurriculars",
		icon: <HiFire />,
		label: "Ekskul",
	},
	{
		key: "events",
		icon: <HiCalendarDays />,
		label: "Kegiatan",
	},
	{
		key: "smartiva-finance-module",
		icon: <HiChartPie />,
		label: "Keuangan",
		children: [
			{
				key: "finance/fees",
				// icon: <HiTicket />,
				label: "Biaya",
			},
			{
				key: "finance/invoices",
				// icon: <HiReceiptPercent />,
				label: "Tagihan",
			},
			{
				key: "finance/transactions",
				// icon: <HiArrowPathRoundedSquare />,
				label: "Transaksi",
			},
		],
	},
	{
		key: "smartiva-payment-module",
		icon: <HiShieldCheck />,
		label: "Pembayaran",
		children: [
			{
				key: "payment/transactions",
				// icon: <HiArrowPathRoundedSquare />,
				label: "Transaksi",
			},
		],
	},
	{
		key: "smartiva-pocket-module",
		icon: <HiWallet />,
		label: "Tabungan",
		children: [
			{
				key: "pocket/accounts",
				// icon: <HiCreditCard />,
				label: "Rekening",
			},
		],
	},
	{
		key: "smartiva-report-module",
		icon: <HiDocumentChartBar />,
		label: "Rapor",
		children: [
			{
				key: "report/assessments",
				// icon: <HiClipboardDocumentList />,
				label: "Jenis Penilaian",
			},
			{
				key: "report/cards",
				// icon: <HiDocumentChartBar />,
				label: "Buku Rapor",
			},
		],
	},
	{
		key: "publications",
		icon: <HiMegaphone />,
		label: "Publikasi",
		children: [
			{
				key: "publication/announcements",
				// icon: <HiMegaphone />,
				label: "Pengumuman",
			},
			{
				key: "publication/articles",
				// icon: <HiDocumentText />,
				label: "Artikel",
			},
		],
	},
	{
		key: "other",
		icon: <HiBars3 />,
		label: "Lain-lain",
		children: [
			{
				key: "other/settings",
				// icon: <HiCog6Tooth />,
				label: "Pengaturan",
			},
		],
	},
];

const teacherMenu = [
	{
		key: "dashboard",
		icon: <HiRectangleGroup />,
		label: "Dasbor",
	},
	{
		key: "academic",
		icon: <HiBookOpen />,
		label: "Akademik",
		children: [
			{
				key: "academic/classrooms",
				// icon: <HiBuildingOffice />,
				label: "Kelas & Pelajaran",
			},
		],
	},
	{
		key: "events",
		icon: <HiCalendarDays />,
		label: "Kegiatan",
	},
];

type Year = {
	name: string;
	semesters: {
		name: string;
		status: string;
	}[];
};

function CustomSider({
	collapsed,
	setCollapsed,
	query,
}: {
	collapsed: boolean;
	setCollapsed: any;
	query: string;
}) {
	const context = useContext(AppContext);
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const page = pathname.replace("/", "");
	const [year, setYear] = useState<Year | null>(null);
	const [yearPanel, setYearPanel] = useState<CollapseProps["items"]>([]);

	const [matchedKeys, setMatchedKeys] = useState<string[]>([]);
	const [openKeys, setOpenKeys] = useState<string[]>([]);
	const [menu, setMenu] = useState<any[]>(
		context.role === "ADMIN" || context.role === "MASTER" ? adminMenu : teacherMenu
	);

	const {
		token: { colorPrimary, colorPrimaryText },
	} = theme.useToken();

	useEffect(() => {
		fetchActiveYear();
		setMenu(context.role === "ADMIN" || context.role === "MASTER" ? adminMenu : teacherMenu);
		setDefaultOpenKey();
		setMatchedKeys([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [context.role]);

	useEffect(() => {
		if (year) {
			const items: CollapseProps["items"] = [
				{
					label: (
						<strong
							style={{
								color: "white",
							}}>
							{year?.name}
						</strong>
					),
					children: year && year.semesters && (
						<Space direction="vertical" size="small" style={{ width: "100%" }}>
							{year.semesters.map((semester) => (
								<div key={semester.name}>
									<Typography.Text
										style={{
											color: "white",
										}}>
										{semester.name}
									</Typography.Text>
									{semester.status === "ACTIVE" && (
										<Tag style={{ marginLeft: 8 }} color="green">
											Aktif
										</Tag>
									)}
								</div>
							))}
						</Space>
					),
				},
			];
			setYearPanel(items);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [year]);

	useEffect(() => {
		handleSearchMenu();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query]);

	const setDefaultOpenKey = () => {
		const activeKey = page.split("/")[1];
		if (activeKey) {
			const openKey = menu.find((item) =>
				item.children?.some((child: any) => child.key === activeKey)
			)?.key;

			if (openKey) {
				setOpenKeys([openKey]);
			}
		}
	};

	const fetchActiveYear = () => {
		if (context.role === "ADMIN" || context.role === "MASTER") {
			adminGetActiveYear()
				.then((res) => {
					const year = res.data.data;
					setYear(year);
					context.setYear(year);
				})
				.catch((error) => {
					console.log(error);
				});
		} else if (context.role === "TEACHER") {
			teacherGetActiveYear()
				.then((res) => {
					const year = res.data.data;
					setYear(year);
					context.setYear(year);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};

	const handleSearchMenu = () => {
		if (!query) {
			setMatchedKeys([]);
			setDefaultOpenKey();
			setMenu(context.role === "ADMIN" || context.role === "MASTER" ? adminMenu : teacherMenu);
			return;
		}
		// hightlight menu where the label contains the query
		const item = context.role === "ADMIN" || context.role === "MASTER" ? adminMenu : teacherMenu;
		const matchedKeys: string[] = [];
		const openKeys: string[] = [];
		if (context.role === "ADMIN" || context.role === "MASTER") {
			adminMenu?.forEach((item) => {
				if (item?.label.toLowerCase().includes(query.toLowerCase())) {
					matchedKeys.push(item.key);
				}
				if (item.children) {
					item.children.forEach((child) => {
						if (child.label.toLowerCase().includes(query.toLowerCase())) {
							matchedKeys.push(child.key);
							openKeys.push(item.key);
						}
					});
				}
			});
		} else {
			teacherMenu?.forEach((item) => {
				if (item?.label.toLowerCase().includes(query.toLowerCase())) {
					matchedKeys.push(item.key);
				}
				if (item.children) {
					item.children.forEach((child) => {
						if (child.label.toLowerCase().includes(query.toLowerCase())) {
							matchedKeys.push(child.key);
							openKeys.push(item.key);
						}
					});
				}
			});
		}

		// set label to bold where the label contains the query
		const newMenu = item.map((item) => {
			if (item.label.toLowerCase().includes(query.toLowerCase())) {
				const matchLabelIndex = item.label.toLowerCase().indexOf(query.toLowerCase());
				const beforeMatch = item.label.slice(0, matchLabelIndex);
				const match = item.label.slice(matchLabelIndex, matchLabelIndex + query.length);
				const afterMatch = item.label.slice(matchLabelIndex + query.length);

				return {
					...item,
					label: (
						<span key={item.key}>
							{beforeMatch}
							<Typography.Text strong type="danger">
								{match}
							</Typography.Text>
							{afterMatch}
						</span>
					),
				};
			}
			if (item.children) {
				const children = item.children.map((child) => {
					if (child.label.toLowerCase().includes(query.toLowerCase())) {
						const matchLabelIndex = child.label.toLowerCase().indexOf(query.toLowerCase());
						const beforeMatch = child.label.slice(0, matchLabelIndex);
						const match = child.label.slice(matchLabelIndex, matchLabelIndex + query.length);
						const afterMatch = child.label.slice(matchLabelIndex + query.length);

						return {
							...child,
							label: (
								<span key={child.key}>
									{beforeMatch}
									<Typography.Text strong type="danger">
										{match}
									</Typography.Text>
									{afterMatch}
								</span>
							),
						};
					}
					return child;
				});
				return {
					...item,
					children,
				};
			}
			return item;
		});

		setMenu(newMenu);
		setOpenKeys(openKeys);
		setMatchedKeys(matchedKeys);
	};

	return (
		<Sider
			theme="light"
			style={siderStyle}
			width={248}
			collapsed={collapsed}
			onCollapse={(value) => setCollapsed(value)}>
			<div
				style={{
					height: 40,
					// backgroundColor: colorBgContainer,
					// borderRadius: borderRadiusLG,
					margin: "16px",
					alignContent: "center",
					alignItems: "center",
					display: "flex",
					justifyContent: "center",
				}}>
				<img
					src={
						context.user.school?.logo && context.user.school?.logo.url
							? context.user.school.logo.url
							: logo
					}
					alt="logo"
					style={{
						height: "100%",
						objectFit: "contain",
					}}
				/>
				{!collapsed && (
					<div
						style={{
							marginLeft: 8,
							display: "flex",
							flexDirection: "column",
						}}>
						<Typography.Text
							style={{
								margin: 0,
								whiteSpace: "nowrap",
								color: colorPrimaryText,
								fontWeight: "bold",
							}}>
							{context.user.school?.name || "Smartiva"}
						</Typography.Text>
						<Typography.Text
							style={{
								fontSize: 8,
								fontWeight: "lighter",
								color: colorPrimaryText,
							}}>
							powered by Smartiva
						</Typography.Text>
					</div>
				)}
			</div>
			{!collapsed && (
				<div
					style={{
						marginTop: 12,
					}}>
					<Collapse
						bordered={false}
						accordion={false}
						style={{
							backgroundColor: colorPrimary,
							border: "none",
							borderRadius: 0,
						}}
						expandIcon={({ isActive }) => (
							<CaretRightOutlined
								style={{
									color: "white",
								}}
								rotate={isActive ? 90 : 0}
							/>
						)}
						items={yearPanel}
					/>
				</div>
			)}
			<Menu
				style={{
					marginTop: 16,
					padding: "0 12px",
				}}
				theme="light"
				mode="inline"
				openKeys={openKeys}
				selectedKeys={[page === "" ? "dashboard" : page.split("/")[1]]}
				onOpenChange={(keys) => setOpenKeys(keys as string[])}
				items={menu.filter(
					(item) =>
						matchedKeys.includes(item.key) ||
						item.children?.some((child: any) => matchedKeys.includes(child.key)) ||
						!query
				)}
				onClick={({ key }) => {
					const role = context.role === "ADMIN" || context.role === "MASTER" ? "admin" : "teacher";
					navigate(`/${role}/${key}`);
				}}
			/>
			<div
				style={{
					height: 16,
				}}
			/>
		</Sider>
	);
}

export default CustomSider;
