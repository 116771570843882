import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import "@fontsource/plus-jakarta-sans";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AppContextProvider } from "./store/app.context";
import { App as AntdApp, ConfigProvider } from "antd";
import locale from "antd/locale/id_ID";
import "antd/dist/reset.css";
import { BrowserRouter } from "react-router-dom";

Sentry.init({
  dsn: "https://9201b4b7eaa85060354b14def10dc24e@o4507888135766016.ingest.us.sentry.io/4508131885842432",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^\//],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <AppContextProvider>
    <ConfigProvider
      locale={locale}
      theme={{
        token: {
          colorPrimary: "#01ABE9",
          colorSuccess: "#29D697",
          colorWarning: "#FFC107",
          colorError: "#F2583E",
          colorTextHeading: "#333333",
          colorText: "#333333",
          fontFamily: "Plus Jakarta Sans, sans-serif",
        },
      }}
    >
      <AntdApp>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AntdApp>
    </ConfigProvider>
  </AppContextProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
