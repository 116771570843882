/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Modal, notification, Popconfirm, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import {
	requestAssignTeacherToExtracurricular,
	requestGetTeacherList,
} from "../../../../services/admin.service";

const ExtracurricularTeacherAssignFormModal = ({
	extracurricular,
	isVisible,
	isLoading,
	onLoading,
	onClose,
	onSuccess,
}) => {
	const [form] = Form.useForm();

	const [teachers, setTeachers] = useState([]);

	useEffect(() => {
		fetchTeacherList();
	}, []);

	const fetchTeacherList = () => {
		onLoading(true);
		requestGetTeacherList({ page: 1, limit: 1000 })
			.then((response) => {
				setTeachers(response.data.data);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				onLoading(false);
			});
	};

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				onLoading(true);
				if (extracurricular) {
					values.teacherIds = [values.teacherIds];
					requestAssignTeacherToExtracurricular(extracurricular._id, values)
						.then((response) => {
							notification["success"]({
								message: "Good job!",
								description: response.data.message,
							});
							form.resetFields();
							onSuccess();
						})
						.catch((error) => {
							if (error.response && error.response.data) {
								notification["error"]({
									message: "Kesalahan!",
									description: error.response.data.message,
								});
							} else {
								notification["error"]({
									message: "Kesalahan!",
									description: error.message,
								});
							}
						})
						.finally(() => {
							onLoading(false);
						});
				}
			})
			.catch((error) => {});
	};

	const handleClose = () => {
		onLoading(false);
		form.resetFields();
		onClose();
	};

	return (
		<>
			{extracurricular && (
				<Modal
					maskClosable={false}
					width={512}
					title={`Tugaskan Pengajar ke Ekskul ${extracurricular.name}`}
					open={isVisible}
					cancelText="Batal"
					onCancel={handleClose}
					footer={[
						<Button key={1} onClick={handleClose}>
							Batal
						</Button>,
						<Popconfirm
							key={2}
							onConfirm={handleSubmit}
							title="Yakin akan menugaskan pengajar ini?"
							okText="Ya"
							cancelText="Tidak">
							<Button type="primary">{"Tugaskan"}</Button>
						</Popconfirm>,
					]}>
					<Spin spinning={isLoading}>
						<Form form={form} layout="vertical" requiredMark="optional">
							<Form.Item
								style={{ marginTop: 0, marginBottom: 0 }}
								name="teacherIds"
								label="Nama"
								required
								rules={[{ required: true, message: "Pilih pengajar!" }]}
								tooltip="Pilih pengajar">
								<Select
									mode={extracurricular ? null : "multiple"}
									placeholder="Pilih Pengajar"
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) =>
										option.children.toLowerCase().includes(input.toLowerCase())
									}
									filterSort={(optionA, optionB) =>
										optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
									}>
									{teachers.map((teacher) => (
										<Select.Option key={teacher._id} value={teacher._id}>
											{`[${teacher.number}] ${teacher.name}`}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Form>
					</Spin>
				</Modal>
			)}
		</>
	);
};

export default ExtracurricularTeacherAssignFormModal;
