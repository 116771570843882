/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
	PlusCircleFilled,
	ExclamationCircleFilled,
	MoreOutlined,
	CopyFilled,
} from "@ant-design/icons";
import { App, Button, Card, Col, Dropdown, Row, Space, Table, theme } from "antd";
import { useEffect, useLayoutEffect, useState } from "react";
import {
	deleteClassroom,
	requestGetClassroomList,
	requestGetYearList,
} from "../../../services/admin.service";
import ClassroomFormModal from "./components/ClassroomFormModal";
import ClassroomDetailModal from "./components/ClassroomDetailModal";
import { useContext } from "react";
import AppContext from "../../../store/app.context.tsx";
import { HiOutlineEye, HiOutlinePencil, HiOutlineTrash } from "react-icons/hi2";
import SearchInput from "../../../components/SearchInput";
import { YearFilter } from "../../../components/YearFilter";
import ClassroomCloneFormModal from "./components/ClassroomCloneFormModal";
import { useSearchParams } from "react-router-dom";

const { useToken } = theme;

const columns = [
	{
		title: "Nama",
		dataIndex: "name",
		key: "name",
		sorter: true,
	},
	{
		title: "Tingkat",
		dataIndex: "grade",
		key: "grade",
		sorter: true,
		filters: Array.from(Array(12).keys()).map((i) => ({
			text: `Kelas ${i + 1}`,
			value: i + 1,
		})),
	},
	{
		title: "Wali Kelas",
		dataIndex: "advisorTeacher",
		key: "advisorTeacher",
	},
	{
		title: "Siswa",
		dataIndex: "studentCount",
		key: "studentCount",
	},
	{
		title: "Aksi",
		dataIndex: "actions",
		key: "actions",
		align: "right",
	},
];

const ClassroomPage = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const context = useContext(AppContext);
	const { token } = useToken();
	const { message, notification, modal } = App.useApp();

	useLayoutEffect(() => {
		document.title = "Ruang Admin | Akademik | Kelas";
	}, []);

	const [years, setYears] = useState([]);
	const [filteredBy, setFilteredBy] = useState("");
	const [selectedYearId, setSelectedYearId] = useState("");

	// SEARCH, FILTER, PAGINATION
	const [keyword, setKeyword] = useState("");
	const [filter, setFilter] = useState({});
	const [sorter, setSorter] = useState({
		grade: "asc",
		name: "asc",
	});
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);

	const handleSearch = (value) => {
		setKeyword(value.trim());
		setPage(1);
	};

	const handlePaginate = (pagination, filters, sorter) => {
		setPage(pagination.current);
		setLimit(pagination.pageSize);

		// UPDATE FILTER
		if (filters) {
			const additionalFilter = {};
			Object.keys(filters).forEach((key) => {
				if (filters[key]) {
					additionalFilter[key] = filters[key].join("|");
				} else {
					additionalFilter[key] = "";
				}
			});

			setFilter({
				...filter,
				...additionalFilter,
			});
		} else {
			const tempFilter = {};
			// only allow startedAt and endedAt
			Object.keys(filter).forEach((key) => {
				if (key === "grade" || key === "name") {
					tempFilter[key] = filter[key];
				}
			});

			setFilter(tempFilter);
		}

		// UPDATE SORTER
		if (sorter && sorter.field) {
			setSorter({
				[sorter.field]: sorter.order === "ascend" ? "asc" : "desc",
			});
		} else {
			setSorter({
				grade: "asc",
				name: "asc",
			});
		}
	};
	// SEARCH, FILTER, PAGINATION

	const [classrooms, setClassrooms] = useState([]);
	const [mappedClassrooms, setMappedClassrooms] = useState([]);
	const [isLoading, setLoading] = useState(false);

	const [classroom, setClassroom] = useState(null);
	const [isClassroomFormVisible, setClassroomFormVisible] = useState(false);
	const [isClassroomDetailVisible, setClassroomDetailVisible] = useState(false);
	const [isClassroomCloneFormVisible, setClassroomCloneFormVisible] = useState(false);

	useEffect(() => {
		setSelectedYearId(context.year._id);
		fetchYearList();
	}, []);

	useEffect(() => {
		setSearchParams({ keyword: keyword, page: page, limit: limit });

		if (filter.year) {
			fetchClassroomList();
		}
	}, [keyword, filter, page, limit]);

	useEffect(() => {
		if (searchParams.has("keyword")) {
			setKeyword(searchParams.get("keyword"));
		}
		if (searchParams.has("page")) {
			setPage(parseInt(searchParams.get("page")));
		}
		if (searchParams.has("limit")) {
			setLimit(parseInt(searchParams.get("limit")));
		}
	}, []);

	useEffect(() => {
		if (selectedYearId !== "") {
			const year = years.find((year) => year._id === selectedYearId);
			if (year) {
				setFilteredBy(year.name);
			} else {
				setFilteredBy(context.year.name);
			}
		} else {
			setFilteredBy("");
		}
		setFilter({
			...filter,
			year: selectedYearId,
		});
	}, [selectedYearId]);

	const fetchYearList = () => {
		requestGetYearList({
			page: 1,
			limit: 100,
		})
			.then((response) => {
				setYears(response.data.data);
			})
			.catch((error) => {});
	};

	const fetchClassroomList = () => {
		setLoading(true);
		let tempFilter = "";
		if (filter) {
			tempFilter = Object.keys(filter)
				.map((key) => `${key}:${filter[key]}`)
				.filter(
					(f) => f.split(":")[1] !== "" && f.split(":")[1] !== undefined && f.split(":")[1] !== null
				)
				.join(",");
		}

		let tempSorter = "";
		if (sorter) {
			tempSorter = Object.keys(sorter)
				.map((key) => `${key}:${sorter[key]}`)
				.filter(
					(s) => s.split(":")[1] !== "" && s.split(":")[1] !== undefined && s.split(":")[1] !== null
				)
				.join(",");
		}

		requestGetClassroomList({
			page: page,
			limit: limit,
			order: tempSorter ?? "grade:asc,name:asc",
			keyword: keyword ?? "",
			filter: tempFilter ?? "",
		})
			.then((response) => {
				setTotal(response.data.pagination.total);
				setClassrooms(response.data.data);
				updateMappedClassroom(response.data.data);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const updateMappedClassroom = (data) => {
		setMappedClassrooms(
			data.map((d) => ({
				key: d._id,
				name: d.name,
				grade: d.grade,
				advisorTeacher: d.teachers
					.map((t) => `${t.name} (${t.type === "ADVISOR" ? "Wali" : "Asisten"})`)
					.join(", "),
				studentCount: d.students.length,
				actions: (
					<Space>
						<Dropdown
							trigger={["click"]}
							menu={{
								onClick: (e) => {
									if (e.key === "edit") {
										handleEditButton(d);
									} else if (e.key === "show") {
										handleShowButton(d);
									} else if (e.key === "delete") {
										modal.confirm({
											title: "Sebentar",
											icon: <ExclamationCircleFilled />,
											content:
												"Anda yakin akan menghapus kelas ini? Tindakan ini tidak dapat dibatalkan. Semua data terkait akan terputus.",
											okText: "Ya",
											okType: "danger",
											cancelText: "Tidak",
											onOk() {
												handleDeleteButton(d._id);
											},
											onCancel() {},
										});
									}
								},
								items: otherButtons,
							}}
							placement="bottomRight"
							arrow>
							<Button type="default" shape="circle" size="small" icon={<MoreOutlined />} />
						</Dropdown>
					</Space>
				),
			}))
		);
	};

	const handleAddButton = () => {
		setClassroomFormVisible(true);
	};

	const handleEditButton = (classroom) => {
		setClassroom(classroom);
		setClassroomFormVisible(true);
	};

	const handleShowButton = (classroom) => {
		setClassroom(classroom);
		setClassroomDetailVisible(true);
	};

	const handleDeleteButton = (id) => {
		setLoading(true);
		deleteClassroom(id)
			.then((response) => {
				if (response.data.code === 200) {
					notification["success"]({
						message: "Good job!",
						description: response.data.message,
					});

					fetchClassroomList();
				}
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleOnCloseClassroomForm = () => {
		setClassroom(null);
		setClassroomFormVisible(false);
	};

	const handleOnSuccessClassroomForm = () => {
		setClassroom(null);
		setClassroomFormVisible(false);
		fetchClassroomList();
	};

	const handleOnCloseClassroomDetail = () => {
		setClassroom(null);
		setClassroomDetailVisible(false);
	};

	const handleOnSuccessClassroomDetail = () => {
		setClassroom(null);
		setClassroomDetailVisible(false);
		fetchClassroomList();
	};

	const otherButtons = [
		{
			key: "show",
			label: "Lihat",
			icon: <HiOutlineEye />,
		},
		{
			key: "edit",
			label: "Edit",
			icon: <HiOutlinePencil />,
		},
		{
			type: "divider",
		},
		{
			key: "delete",
			label: "Hapus",
			icon: <HiOutlineTrash />,
			danger: true,
		},
	];

	const handleCloneButton = () => {
		setClassroomCloneFormVisible(true);
	};

	const handleOnCloseClassroomCloneForm = () => {
		setClassroomCloneFormVisible(false);
	};

	const handleOnSuccessClassroomCloneForm = () => {
		setClassroomCloneFormVisible(false);
		fetchClassroomList();
	};

	return (
		<>
			<ClassroomCloneFormModal
				isLoading={isLoading}
				isVisible={isClassroomCloneFormVisible}
				onLoading={(v) => setLoading(v)}
				onClose={handleOnCloseClassroomCloneForm}
				onSuccess={handleOnSuccessClassroomCloneForm}
				years={years}
			/>
			<ClassroomFormModal
				isLoading={isLoading}
				isVisible={isClassroomFormVisible}
				onLoading={(v) => setLoading(v)}
				classroom={classroom}
				yearId={selectedYearId}
				years={years}
				onClose={handleOnCloseClassroomForm}
				onSuccess={handleOnSuccessClassroomForm}
			/>
			<ClassroomDetailModal
				year={classroom ? years.find((y) => y._id === classroom.yearId) : null}
				isLoading={isLoading}
				isVisible={isClassroomDetailVisible}
				onLoading={(v) => setLoading(v)}
				id={classroom ? classroom._id : null}
				onClose={handleOnCloseClassroomDetail}
				activeYear={context.year}
			/>
			<Card
				bordered={false}
				title="Daftar Kelas"
				extra={
					<Space>
						<SearchInput
							placeholder="Nama kelas"
							onSearch={handleSearch}
							defaultValue={keyword}
							isLoading={isLoading}
						/>
						<YearFilter
							activeYear={context.year}
							years={years}
							filteredBy={{
								key: selectedYearId,
								value: filteredBy,
							}}
							filterBy={(key) => {
								setSelectedYearId(key);
							}}
						/>
						<Button icon={<PlusCircleFilled />} type="primary" onClick={handleAddButton}>
							<span className="ant-btn-text">Tambah</span>
						</Button>
						<Button icon={<CopyFilled />} type="default" onClick={handleCloneButton}>
							<span className="">Duplikasi</span>
						</Button>
					</Space>
				}>
				<div>
					<Table
						bordered
						columns={columns}
						loading={isLoading}
						dataSource={mappedClassrooms}
						pagination={{
							current: page,
							total: total,
							position: "bottomRight",
							pageSizeOptions: ["10", "20", "50", "100"],
							showSizeChanger: true,
							locale: { items_per_page: "item/hal" },
						}}
						onChange={handlePaginate}
						className="ant-border-classroom"
					/>
				</div>
			</Card>
		</>
	);
};

export default ClassroomPage;
