/* eslint-disable react-hooks/exhaustive-deps */
import { App, Button, Form, Modal, Popconfirm, Select, Spin, Table } from "antd";
import React, { useEffect } from "react";
import { editLesson, requestGenerateLessons } from "../../../../services/admin.service";

const LessonFormModal = ({
	curriculums,
	lesson,
	yearId,
	isVisible,
	isLoading,
	onLoading,
	onClose,
	onSuccess,
	years,
	subjects,
	classrooms,
}) => {
	const { notification } = App.useApp();

	const [form] = Form.useForm();
	const curriculumId = Form.useWatch("curriculumId", form);
	const [availableSubjects, setAvailableSubjects] = React.useState([]);

	useEffect(() => {
		if (curriculums.length > 0) {
			const curriculum = curriculums.find(
				(curriculum) => curriculum._id === form.getFieldValue("curriculumId")
			);
			if (curriculum) {
				const curriculumSubjectIds = curriculum.subjectIds;

				const availableSubjects = subjects.filter((subject) =>
					curriculumSubjectIds.includes(subject._id)
				);

				setAvailableSubjects(availableSubjects);
			} else {
				setAvailableSubjects([]);
			}
		} else {
			setAvailableSubjects([]);
		}
	}, [curriculumId]);

	useEffect(() => {
		if (lesson) {
			form.setFieldsValue({
				yearId: yearId,
				curriculumId: lesson.curriculumId,
				subjectId: lesson.subjectId,
				classroomId: lesson.classroomId,
			});
		} else {
			form.setFieldsValue({
				yearId: yearId,
			});
		}
	}, [lesson, isVisible]);

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				onLoading(true);
				if (lesson) {
					values.id = lesson._id;
					editLesson(values)
						.then((response) => {
							notification["success"]({
								message: "Good job!",
								description: response.data.message,
							});
							form.resetFields();
							onSuccess();
						})
						.catch((error) => {
							if (error.response && error.response.data) {
								notification["error"]({
									message: "Kesalahan!",
									description: error.response.data.message,
								});
							} else {
								notification["error"]({
									message: "Kesalahan!",
									description: error.message,
								});
							}
						})
						.finally(() => {
							onLoading(false);
						});
				} else {
					requestGenerateLessons(values)
						.then((response) => {
							notification["success"]({
								message: "Good job!",
								description: response.data.message,
							});
							form.resetFields();
							onSuccess();
						})
						.catch((error) => {
							if (error.response && error.response.data) {
								notification["error"]({
									message: "Kesalahan!",
									description: error.response.data.message,
								});
							} else {
								notification["error"]({
									message: "Kesalahan!",
									description: error.message,
								});
							}
						})
						.finally(() => {
							onLoading(false);
						});
				}
			})
			.catch((error) => {});
	};

	const handleClose = () => {
		form.resetFields();
		onClose();
	};

	return (
		<>
			<Modal
				maskClosable={false}
				forceRender
				width={512}
				title={lesson === null || lesson === undefined ? "Tambah Pelajaran" : "Ubah Pelajaran"}
				open={isVisible}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Batal
					</Button>,
					<Popconfirm
						key={2}
						onConfirm={handleSubmit}
						title="Yakin akan menyimpan data pelajaran?"
						okText="Ya"
						cancelText="Tidak">
						<Button type="primary">{lesson ? "Simpan" : "Tambah"}</Button>
					</Popconfirm>,
				]}>
				<Spin spinning={isLoading}>
					<Form form={form} layout="vertical" requiredMark="optional">
						<Form.Item
							style={{ marginTop: 0, marginBottom: 0 }}
							name="yearId"
							label="Tahun Akademik"
							required
							rules={[
								{
									required: true,
									message: "Tahun akademik wajib dipilih!",
								},
							]}
							tooltip="Pilih tahun akademik">
							<Select
								placeholder="Pilih Tahun Akademik"
								showSearch
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.children.toLowerCase().includes(input.toLowerCase())
								}
								filterSort={(optionA, optionB) =>
									optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
								}
								disabled>
								{years.map((year) => {
									return (
										<Select.Option key={year._id} value={year._id}>
											{year.name}
										</Select.Option>
									);
								})}
							</Select>
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="curriculumId"
							label="Kurikulum"
							required
							rules={[
								{
									required: true,
									message: "Kurikulum wajib dipilih!",
								},
							]}
							tooltip="Pilih kurikulum">
							<Select
								onChange={(value) => {
									console.log(value);
								}}
								placeholder="Pilih Kurikulum"
								showSearch
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.children.toLowerCase().includes(input.toLowerCase())
								}
								filterSort={(optionA, optionB) =>
									optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
								}>
								{curriculums.map((curriculum) => {
									return (
										<Select.Option key={curriculum._id} value={curriculum._id}>
											{curriculum.name}
										</Select.Option>
									);
								})}
							</Select>
						</Form.Item>
						{/* table of subjects */}
						<Table
							style={{ marginTop: 12 }}
							size="small"
							columns={[
								{
									title: "No",
									dataIndex: "number",
									key: "number",
									width: 36,
								},
								{
									title: "Mata Pelajaran",
									dataIndex: "name",
									key: "name",
								},
							]}
							dataSource={
								availableSubjects !== undefined
									? availableSubjects.map((subject, index) => {
											return {
												_id: subject._id,
												number: index + 1,
												name: subject.name,
											};
									  })
									: []
							}
							pagination={false}
							rowKey={(record) => record._id}
						/>
						{/* <Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="subjectId"
							label="Mata Pelajaran"
							required
							rules={[
								{
									required: true,
									message: "Mata pelajaran wajib dipilih!",
								},
							]}
							tooltip="Pilih mata pelajaran">
							<Select
								placeholder="Pilih Mata Pelajaran"
								showSearch
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.children.toLowerCase().includes(input.toLowerCase())
								}
								filterSort={(optionA, optionB) =>
									optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
								}>
								{availableSubjects !== undefined &&
									availableSubjects.map((subject) => {
										return (
											<Select.Option key={subject._id} value={subject._id}>
												{subject.name}
											</Select.Option>
										);
									})}
							</Select>
						</Form.Item>
						<Typography.Text
							type="secondary"
							style={{
								display: "block",
								marginBottom: "16px",
							}}>
							Opsi pelajaran didapatkan berdasarkan kurikulum. Bila Anda tidak menemukan mata
							pelajaran yang Anda cari, silakan hubungi kami untuk pengadaaan mata pelajaran baru.
						</Typography.Text> */}
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="classroomId"
							label="Kelas"
							required
							rules={[
								{
									required: true,
									message: "Kelas wajib dipilih!",
								},
							]}
							tooltip="Pilih kelas">
							<Select
								placeholder="Pilih Kelas"
								showSearch
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.children.toLowerCase().includes(input.toLowerCase())
								}
								filterSort={(optionA, optionB) =>
									optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
								}>
								{classrooms.map((classroom) => {
									return (
										<Select.Option key={classroom._id} value={classroom._id}>
											{classroom.name}
										</Select.Option>
									);
								})}
							</Select>
						</Form.Item>
					</Form>
				</Spin>
			</Modal>
		</>
	);
};

export default LessonFormModal;
